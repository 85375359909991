"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createMobileSeriesData = exports.createFixedSeriesData = void 0;
const createFixedSeriesData = (data) => (data || [])
    .map(({ time, value }) => [time, value])
    .sort((a, b) => a[0] - b[0]);
exports.createFixedSeriesData = createFixedSeriesData;
const createMobileSeriesData = (data, isGraphData) => data
    .map((measurement) => ({
    x: measurement.time,
    y: isGraphData && "lastMeasurementValue" in measurement
        ? measurement.lastMeasurementValue
        : measurement.value,
    position: {
        lat: isGraphData && "point" in measurement
            ? measurement.point.lat
            : measurement.latitude,
        lng: isGraphData && "point" in measurement
            ? measurement.point.lng
            : measurement.longitude,
    },
}))
    .filter((point) => point.x !== undefined)
    .sort((a, b) => a.x - b.x);
exports.createMobileSeriesData = createMobileSeriesData;
