"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CrowdMapToggle = void 0;
const lodash_1 = require("lodash");
const react_1 = __importStar(require("react"));
const react_i18next_1 = require("react-i18next");
const userStates_1 = require("../../types/userStates");
const mapParamsHandler_1 = require("../../utils/mapParamsHandler");
const Toggle_1 = require("../Toggle/Toggle");
const CrowdMapGridSize_1 = require("./CrowdMapGridSize");
const FilterInfoPopup_1 = require("./FilterInfoPopup");
const S = __importStar(require("./SessionFilters.style"));
const CrowdMapToggle = () => {
    const { currentUserSettings, goToUserSettings, previousUserSettings, setUrlParams, updatePreviousUserSettings, } = (0, mapParamsHandler_1.useMapParams)();
    const { t } = (0, react_i18next_1.useTranslation)();
    const getInitialMobileState = () => window.matchMedia("(max-width: 1023px)").matches;
    const getInitialCrowdMapState = () => {
        return getInitialMobileState()
            ? previousUserSettings === userStates_1.UserSettings.CrowdMapView
            : currentUserSettings === userStates_1.UserSettings.CrowdMapView;
    };
    const [isMobile, setIsMobile] = (0, react_1.useState)(getInitialMobileState);
    const [isCrowdMapActive, setIsCrowdMapActive] = (0, react_1.useState)(getInitialCrowdMapState);
    const [renderGridSize, setRenderGridSize] = (0, react_1.useState)(isCrowdMapActive);
    const isFiltersViewActive = currentUserSettings === userStates_1.UserSettings.FiltersView;
    (0, react_1.useEffect)(() => {
        const checkMobile = (0, lodash_1.debounce)(() => {
            const mobileState = window.matchMedia("(max-width: 1023px)").matches;
            setIsMobile(mobileState);
        }, 200);
        window.addEventListener("resize", checkMobile);
        return () => {
            window.removeEventListener("resize", checkMobile);
            checkMobile.cancel();
        };
    }, []);
    (0, react_1.useEffect)(() => {
        const newCrowdMapState = isMobile
            ? previousUserSettings === userStates_1.UserSettings.CrowdMapView
            : currentUserSettings === userStates_1.UserSettings.CrowdMapView;
        if (newCrowdMapState !== isCrowdMapActive) {
            setIsCrowdMapActive(newCrowdMapState);
        }
    }, [currentUserSettings, previousUserSettings, isMobile, isCrowdMapActive]);
    const handleToggleClick = (0, react_1.useCallback)(() => {
        const newCheckedState = !isCrowdMapActive;
        if (isMobile && isFiltersViewActive) {
            updatePreviousUserSettings(newCheckedState ? userStates_1.UserSettings.CrowdMapView : userStates_1.UserSettings.MapView);
        }
        else {
            goToUserSettings(newCheckedState ? userStates_1.UserSettings.CrowdMapView : userStates_1.UserSettings.MapView);
        }
        if (newCheckedState) {
            setRenderGridSize(true);
            setIsCrowdMapActive(true);
        }
        else {
            setIsCrowdMapActive(false);
            setTimeout(() => setRenderGridSize(false), 500);
        }
    }, [
        isCrowdMapActive,
        isMobile,
        isFiltersViewActive,
        setUrlParams,
        goToUserSettings,
    ]);
    return (react_1.default.createElement(S.Wrapper, null,
        react_1.default.createElement(S.SingleFilterWrapper, null,
            react_1.default.createElement(S.ToggleSettingsContainer, { "$isActive": isCrowdMapActive },
                react_1.default.createElement(S.ToggleWrapper, { onClick: handleToggleClick },
                    react_1.default.createElement(Toggle_1.Toggle, { isChecked: isCrowdMapActive, onChange: handleToggleClick, variant: "toggle", noLabel: true, biggerMobileVersion: true }),
                    react_1.default.createElement(S.CrowdMapToggleText, null,
                        t("filters.crowdMapLabel"),
                        " ",
                        react_1.default.createElement(S.CrowdMapToggleOnOff, null, isCrowdMapActive ? t("filters.on") : t("filters.off")))),
                renderGridSize && (react_1.default.createElement(S.CrowdMapGridSizeWrapper, { "$isVisible": isCrowdMapActive },
                    t("filters.crowdMapGridCellSizeHeader"),
                    react_1.default.createElement(CrowdMapGridSize_1.CrowdMapGridSize, null)))),
            react_1.default.createElement(FilterInfoPopup_1.FilterInfoPopup, { filterTranslationLabel: "filters.crowdMapInfo" }))));
};
exports.CrowdMapToggle = CrowdMapToggle;
