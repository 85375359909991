"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.API_ENDPOINTS = void 0;
exports.API_ENDPOINTS = {
    exportSessionData: (sessionsIds, email) => {
        const sessionParams = sessionsIds
            .map((id) => `session_ids[]=${id}`)
            .join("&");
        return `/sessions/export.json?${sessionParams}&email=${email}`;
    },
    fetchClusterData: (streamIds) => `/fixed_region.json?stream_ids=${streamIds.join(",")}`,
    fetchCrowdMap: (filters) => `/averages2.json?q=${filters}`,
    fetchActiveFixedSessions: (filters) => `/fixed/active/sessions2.json?q=${filters}`,
    fetchDormantFixedSessions: (filters) => `/fixed/dormant/sessions.json?q=${filters}`,
    fetchFixedStreamById: (id) => `/fixed_streams/${id}`,
    fetchMobileSessions: (filters) => `/mobile/sessions.json?q=${filters}`,
    fetchMobileStreamById: (id) => `/mobile/streams/${id}`,
    fetchRectangleData: (filters) => `/region.json?${filters}`,
    fetchSelectedDataRangeOfStream: (id, startDate, endDate) => `/stream_daily_averages?stream_id=${id}&start_date=${startDate}&end_date=${endDate}`,
    fetchThresholds: (filters) => `/thresholds/${filters}`,
    fetchUsernames: (params) => {
        const { usernames, tags, west, east, south, north, timeFrom, timeTo, sensorName, unitSymbol, isIndoor, isActive, sessionType, } = params;
        if (west === undefined ||
            east === undefined ||
            south === undefined ||
            north === undefined ||
            timeFrom === undefined ||
            timeTo === undefined ||
            sensorName === undefined ||
            unitSymbol === undefined ||
            isIndoor === undefined ||
            isActive === undefined ||
            tags === undefined) {
            throw new Error("Missing required parameters.");
        }
        const query = {
            "q[input]": usernames || "",
            "q[west]": west,
            "q[east]": east,
            "q[south]": south,
            "q[north]": north,
            "q[time_from]": timeFrom,
            "q[time_to]": timeTo,
            "q[sensor_name]": sensorName,
            "q[unit_symbol]": unitSymbol,
            "q[is_indoor]": isIndoor,
            "q[session_type]": sessionType,
            "q[is_dormant]": !isActive,
            "q[tags]": tags || "",
        };
        function encodeParamName(name) {
            return name.replace(/[^A-Za-z0-9_\[\]]/g, encodeURIComponent);
        }
        const queryString = Object.keys(query)
            .map((key) => `${encodeParamName(key)}=${encodeURIComponent(String(query[key]))}`)
            .join("&");
        const url = `/autocomplete/usernames?${queryString}`;
        return url;
    },
    fetchTags: (params) => {
        const { tags, west, east, south, north, timeFrom, timeTo, usernames, sensorName, unitSymbol, isIndoor, isActive, sessionType, } = params;
        if (west === undefined ||
            east === undefined ||
            south === undefined ||
            north === undefined ||
            timeFrom === undefined ||
            timeTo === undefined ||
            sensorName === undefined ||
            unitSymbol === undefined ||
            isIndoor === undefined ||
            isActive === undefined) {
            throw new Error("Missing required parameters.");
        }
        const query = {
            "q[input]": tags || "",
            "q[west]": west,
            "q[east]": east,
            "q[south]": south,
            "q[north]": north,
            "q[time_from]": timeFrom,
            "q[time_to]": timeTo,
            "q[usernames]": usernames || "",
            "q[sensor_name]": sensorName,
            "q[unit_symbol]": unitSymbol,
            "q[is_indoor]": isIndoor,
            "q[is_active]": isActive,
        };
        function encodeParamName(name) {
            return name.replace(/[^A-Za-z0-9_\[\]]/g, encodeURIComponent);
        }
        const queryString = Object.keys(query)
            .map((key) => `${encodeParamName(key)}=${encodeURIComponent(String(query[key]))}`)
            .join("&");
        const url = `/${sessionType}/autocomplete/tags?${queryString}`;
        return url;
    },
    fetchSensors: (sessionType) => `/sensors?session_type=${sessionType}Session`,
    fetchTimelapseData: (filters) => `/timelapse.json?q=${filters}`,
    fetchIndoorActiveSessions: (filters) => `/fixed/active/sessions2.json?q=${filters}`,
    fetchIndoorDormantSessions: (filters) => `/fixed/dormant/sessions.json?q=${filters}`,
    fetchMeasurements: (streamId, startTime, endTime) => `/measurements?stream_ids=${streamId}&start_time=${startTime}&end_time=${endTime}`,
};
