"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.cleanSessions = exports.fetchDormantFixedSessions = exports.fetchActiveFixedSessions = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const apiClient_1 = require("../api/apiClient");
const apiEndpoints_1 = require("../api/apiEndpoints");
const getErrorMessage_1 = require("../utils/getErrorMessage");
const logController_1 = require("../utils/logController");
const sessionFiltersSlice_1 = require("./sessionFiltersSlice");
const initialState = {
    fetchableSessionsCount: 0,
    activeSessions: [],
    dormantSessions: [],
    isActiveSessionsFetched: false,
    isDormantSessionsFetched: false,
    status: "IDLE" /* StatusEnum.Idle */,
    error: null,
};
const createSessionFetchThunk = (type, endpoint) => {
    return (0, toolkit_1.createAsyncThunk)(`sessions/fetch${type.charAt(0).toUpperCase() + type.slice(1)}FixedSessions`, (sessionsData, { rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield apiClient_1.oldApiClient.get(endpoint(sessionsData.filters));
            return response.data;
        }
        catch (error) {
            const message = (0, getErrorMessage_1.getErrorMessage)(error);
            const apiError = {
                message,
                additionalInfo: {
                    action: `fetch${type.charAt(0).toUpperCase() + type.slice(1)}FixedSessions`,
                    endpoint: endpoint(sessionsData.filters),
                },
            };
            (0, logController_1.logError)(error, apiError);
            return rejectWithValue(apiError);
        }
    }), {
        condition: (_, { getState }) => {
            const { fixedSessions } = getState();
            return fixedSessions.status !== "PENDING" /* StatusEnum.Pending */;
        },
    });
};
exports.fetchActiveFixedSessions = createSessionFetchThunk(sessionFiltersSlice_1.FixedSessionsTypes.ACTIVE, apiEndpoints_1.API_ENDPOINTS.fetchActiveFixedSessions);
exports.fetchDormantFixedSessions = createSessionFetchThunk(sessionFiltersSlice_1.FixedSessionsTypes.DORMANT, apiEndpoints_1.API_ENDPOINTS.fetchDormantFixedSessions);
const fixedSessionsSlice = (0, toolkit_1.createSlice)({
    name: "fixedSessions",
    initialState,
    reducers: {
        cleanSessions(state) {
            state.fetchableSessionsCount = 0;
            state.activeSessions = [];
            state.dormantSessions = [];
            state.isActiveSessionsFetched = false;
            state.isDormantSessionsFetched = false;
            state.status = "IDLE" /* StatusEnum.Idle */;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.fetchActiveFixedSessions.pending, (state) => {
            state.status = "PENDING" /* StatusEnum.Pending */;
            state.error = null;
        })
            .addCase(exports.fetchActiveFixedSessions.fulfilled, (state, action) => {
            state.status = "FULFILLED" /* StatusEnum.Fulfilled */;
            state.activeSessions = action.payload.sessions;
            state.fetchableSessionsCount = action.payload.fetchableSessionsCount;
            state.isActiveSessionsFetched = true;
            state.error = null;
        })
            .addCase(exports.fetchActiveFixedSessions.rejected, (state, action) => {
            state.status = "REJECTED" /* StatusEnum.Rejected */;
            state.error = action.payload || { message: "Unknown error occurred" };
        });
        builder
            .addCase(exports.fetchDormantFixedSessions.pending, (state) => {
            state.status = "PENDING" /* StatusEnum.Pending */;
            state.error = null;
        })
            .addCase(exports.fetchDormantFixedSessions.fulfilled, (state, action) => {
            var _a;
            state.status = "FULFILLED" /* StatusEnum.Fulfilled */;
            state.dormantSessions = ((_a = action.meta) === null || _a === void 0 ? void 0 : _a.arg.isAdditional)
                ? [...state.dormantSessions, ...action.payload.sessions]
                : action.payload.sessions;
            state.fetchableSessionsCount = action.payload.fetchableSessionsCount;
            state.isDormantSessionsFetched = true;
            state.error = null;
        })
            .addCase(exports.fetchDormantFixedSessions.rejected, (state, action) => {
            state.status = "REJECTED" /* StatusEnum.Rejected */;
            state.error = action.payload || { message: "Unknown error occurred" };
        });
    },
});
exports.cleanSessions = fixedSessionsSlice.actions.cleanSessions;
exports.default = fixedSessionsSlice.reducer;
