"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FocusTabController = void 0;
const react_1 = require("react");
const userIsTabbingKey = "user-is-tabbing";
const handleTabAction = (event) => {
    if (event.key === "Tab" /* KeyboardKeys.Tab */) {
        document.body.classList.add(userIsTabbingKey);
        window.removeEventListener("keydown" /* EventType.keyDown */, handleTabAction);
        window.addEventListener("mousedown" /* EventType.mouseDown */, handleMouseAction);
    }
};
const handleMouseAction = () => {
    document.body.classList.remove(userIsTabbingKey);
    window.removeEventListener("mousedown" /* EventType.mouseDown */, handleMouseAction);
    window.addEventListener("keydown" /* EventType.keyDown */, handleTabAction);
};
const FocusTabController = () => {
    (0, react_1.useEffect)(() => {
        window.addEventListener("keydown" /* EventType.keyDown */, handleTabAction);
        return () => {
            window.removeEventListener("keydown" /* EventType.keyDown */, handleTabAction);
            window.removeEventListener("mousedown" /* EventType.mouseDown */, handleMouseAction);
        };
    }, []);
    return null;
};
exports.FocusTabController = FocusTabController;
