"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useHandleScrollEnd = void 0;
const react_1 = require("react");
const fixedSessionsSlice_1 = require("../store/fixedSessionsSlice");
const hooks_1 = require("../store/hooks");
const mobileSessionsSlice_1 = require("../store/mobileSessionsSlice");
const useHandleScrollEnd = (offset, listSessions, updateOffset, updateFetchedSessions, filters, fetchableMobileSessionsCount, fetchableFixedSessionsCount, isDormant) => {
    const dispatch = (0, hooks_1.useAppDispatch)();
    const handleScrollEnd = (0, react_1.useCallback)(() => {
        const hasMoreSessions = listSessions.length < fetchableMobileSessionsCount;
        const hasMoreDormantSessions = isDormant && listSessions.length < fetchableFixedSessionsCount;
        if (hasMoreSessions) {
            const newOffset = offset + listSessions.length;
            updateOffset(newOffset);
            const updatedFilters = Object.assign(Object.assign({}, JSON.parse(filters)), { offset: newOffset });
            dispatch((0, mobileSessionsSlice_1.fetchMobileSessions)({
                filters: JSON.stringify(updatedFilters),
                isAdditional: true,
            }))
                .unwrap()
                .then((response) => {
                const totalFetchedSessions = listSessions.length + response.sessions.length;
                updateFetchedSessions(totalFetchedSessions);
            });
        }
        if (hasMoreDormantSessions) {
            const newOffset = offset + listSessions.length;
            updateOffset(newOffset);
            const updatedFilters = Object.assign(Object.assign({}, JSON.parse(filters)), { offset: newOffset });
            dispatch((0, fixedSessionsSlice_1.fetchDormantFixedSessions)({
                filters: JSON.stringify(updatedFilters),
                isAdditional: true,
            }))
                .unwrap()
                .then((response) => {
                const totalFetchedSessions = listSessions.length + response.sessions.length;
                updateFetchedSessions(totalFetchedSessions);
            });
        }
    }, [
        offset,
        listSessions.length,
        fetchableMobileSessionsCount,
        updateOffset,
        dispatch,
        filters,
        fetchableFixedSessionsCount,
        isDormant,
    ]);
    return handleScrollEnd;
};
exports.useHandleScrollEnd = useHandleScrollEnd;
