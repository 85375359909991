"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Title = exports.PageContainer = exports.Dot = exports.Description = exports.ContentContainer = exports.BlueButton = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("../../assets/styles/colors");
const Button_style_1 = require("../../components/Button/Button.style");
const media_1 = require("../../utils/media");
const dotFlashing = (0, styled_components_1.keyframes) `
  0% {
    opacity: 0.2;
  }
  20% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
`;
const Dot = styled_components_1.default.span `
  font-size: 24px;
  margin: 0 2px;
  animation: ${dotFlashing} 1.4s infinite both;
  animation-delay: ${({ delay }) => delay};
`;
exports.Dot = Dot;
const PageContainer = styled_components_1.default.div `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  left: 0;
`;
exports.PageContainer = PageContainer;
const ContentContainer = styled_components_1.default.div `
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  color: ${colors_1.gray300};
  width: 80%;

  @media ${media_1.media.desktop} {
    width: 50%;
  }
`;
exports.ContentContainer = ContentContainer;
const Title = styled_components_1.default.h1 `
  font-size: 2rem;
  margin-bottom: 1.25rem;
  font-weight: 500;
  color: ${colors_1.gray400};

  @media ${media_1.media.desktop} {
    font-size: 2.5rem;
  }
`;
exports.Title = Title;
const Description = styled_components_1.default.p `
  font-size: 1.4rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  @media ${media_1.media.desktop} {
    font-size: 1.6rem;
  }
`;
exports.Description = Description;
const BlueButton = (0, styled_components_1.default)(Button_style_1.Button) `
  background-color: ${colors_1.blue};
  color: ${colors_1.white};
  font-weight: 600;
  border: none;
  width: fit-content;
  font-size: 1.4rem;
  padding: 1rem 1.2rem;
  text-transform: uppercase;
`;
exports.BlueButton = BlueButton;
