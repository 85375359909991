"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DateFormat = void 0;
var DateFormat;
(function (DateFormat) {
    DateFormat["default"] = "YYYY-MM-DD";
    DateFormat["default_with_time"] = "YYYY-MM-DD HH:mm";
    DateFormat["us"] = "MM/DD/YYYY";
    DateFormat["us_with_time"] = "MM/DD/YYYY HH:mm";
    DateFormat["us_with_time_seconds"] = "MM/DD/YYYY HH:mm:ss";
    DateFormat["us_without_year"] = "MM/DD";
    DateFormat["time_with_seconds"] = "HH:mm:ss";
    DateFormat["time"] = "hh:mm A";
    DateFormat["us_timestamp"] = "YYYYMMDDHH:mm:ssZ";
    DateFormat["us_with_time_seconds_utc"] = "YYYY-MM-DD HH:mm:ss UTC";
})(DateFormat || (exports.DateFormat = DateFormat = {}));
