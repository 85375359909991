"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MILLISECONDS_IN_A_MONTH = exports.MILLISECONDS_IN_A_WEEK = exports.MILLISECONDS_IN_A_DAY = exports.MILLISECONDS_IN_AN_HOUR = exports.MILLISECONDS_IN_A_5_MINUTES = exports.MILLISECONDS_IN_A_MINUTE = exports.MILLISECONDS_IN_A_SECOND = void 0;
exports.MILLISECONDS_IN_A_SECOND = 1000;
exports.MILLISECONDS_IN_A_MINUTE = 60 * exports.MILLISECONDS_IN_A_SECOND;
exports.MILLISECONDS_IN_A_5_MINUTES = 5 * exports.MILLISECONDS_IN_A_MINUTE;
exports.MILLISECONDS_IN_AN_HOUR = 60 * exports.MILLISECONDS_IN_A_MINUTE;
exports.MILLISECONDS_IN_A_DAY = 24 * exports.MILLISECONDS_IN_AN_HOUR;
exports.MILLISECONDS_IN_A_WEEK = 7 * exports.MILLISECONDS_IN_A_DAY;
exports.MILLISECONDS_IN_A_MONTH = 30 * exports.MILLISECONDS_IN_A_DAY;
