"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UniformDistributionButton = void 0;
const react_1 = __importStar(require("react"));
const react_i18next_1 = require("react-i18next");
const hooks_1 = require("../../../store/hooks");
const movingStreamSelectors_1 = require("../../../store/movingStreamSelectors");
const thresholdSlice_1 = require("../../../store/thresholdSlice");
const userStates_1 = require("../../../types/userStates");
const mapParamsHandler_1 = require("../../../utils/mapParamsHandler");
const uniformDistributionThresholdCalc_1 = require("../../../utils/uniformDistributionThresholdCalc");
const UniformDistributionIcon_1 = require("./Icons/UniformDistributionIcon");
const ThresholdButton_1 = require("./ThresholdButton");
const UniformDistributionButton = ({ variant = ThresholdButton_1.ThresholdButtonVariant.IconOnly, uniformDistributionButtonText, swapIconTextPosition = false, useDarkBlueIcon = false, hasErrorMessage, }) => {
    const dispatch = (0, hooks_1.useAppDispatch)();
    const { sessionId, currentUserSettings } = (0, mapParamsHandler_1.useMapParams)();
    const { t } = (0, react_i18next_1.useTranslation)();
    const mobileStream = (0, hooks_1.useAppSelector)((state) => state.mobileStream);
    const fixedStream = (0, hooks_1.useAppSelector)((state) => state.fixedStream);
    const calendarMinMaxValues = (0, hooks_1.useAppSelector)(movingStreamSelectors_1.selectMovingCalendarMinMax);
    const defaultButtonText = t("thresholdConfigurator.uniformDistributionButtonDesktop");
    const isCalendarView = currentUserSettings === userStates_1.UserSettings.CalendarView;
    const streamMinMaxValues = (0, react_1.useMemo)(() => {
        return (0, uniformDistributionThresholdCalc_1.calculateMinMaxValues)(mobileStream, fixedStream, sessionId);
    }, [mobileStream, fixedStream, sessionId]);
    const minMaxValues = (0, react_1.useMemo)(() => {
        var _a, _b;
        const minMax = isCalendarView ? calendarMinMaxValues : streamMinMaxValues;
        const min = (_a = minMax === null || minMax === void 0 ? void 0 : minMax.min) !== null && _a !== void 0 ? _a : null;
        const max = (_b = minMax === null || minMax === void 0 ? void 0 : minMax.max) !== null && _b !== void 0 ? _b : null;
        return min !== null && max !== null ? { min, max } : null;
    }, [isCalendarView, streamMinMaxValues, calendarMinMaxValues]);
    const distributeThresholds = () => {
        if (!minMaxValues) {
            hasErrorMessage(t("thresholdConfigurator.noValidDataErrorMessage"));
            console.error("No valid data available to distribute thresholds.");
            setTimeout(() => hasErrorMessage(""), 3000);
            return;
        }
        const { min, max } = minMaxValues;
        if (min === max) {
            hasErrorMessage(t("thresholdConfigurator.sameValuesError"));
            console.error("While using discrete uniform distribution, the Min and Max values cannot both be the same.");
            setTimeout(() => hasErrorMessage(""), 3000);
            return;
        }
        const thresholds = (0, uniformDistributionThresholdCalc_1.calculateUniformThresholds)(min, max);
        dispatch((0, thresholdSlice_1.setUserThresholdValues)(thresholds));
    };
    const buttonText = uniformDistributionButtonText || defaultButtonText;
    return (react_1.default.createElement(ThresholdButton_1.ThresholdButton, { variant: variant, buttonText: buttonText, swapIconTextPosition: swapIconTextPosition, useDarkBlueIcon: useDarkBlueIcon, IconComponent: UniformDistributionIcon_1.UniformDistributionIcon, onClick: distributeThresholds }));
};
exports.UniformDistributionButton = UniformDistributionButton;
