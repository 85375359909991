"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.clearMobileSessions = exports.fetchMobileSessions = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const apiClient_1 = require("../api/apiClient");
const apiEndpoints_1 = require("../api/apiEndpoints");
const getErrorMessage_1 = require("../utils/getErrorMessage");
const logController_1 = require("../utils/logController");
const initialState = {
    fetchableSessionsCount: 0,
    sessions: [],
    status: "IDLE" /* StatusEnum.Idle */,
    error: null,
};
exports.fetchMobileSessions = (0, toolkit_1.createAsyncThunk)("sessions/fetchMobileSessions", (sessionsData, { rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield apiClient_1.oldApiClient.get(apiEndpoints_1.API_ENDPOINTS.fetchMobileSessions(sessionsData.filters));
        return response.data;
    }
    catch (error) {
        const message = (0, getErrorMessage_1.getErrorMessage)(error);
        const apiError = {
            message,
            additionalInfo: {
                action: "fetchMobileSessions",
                endpoint: apiEndpoints_1.API_ENDPOINTS.fetchMobileSessions(sessionsData.filters),
            },
        };
        (0, logController_1.logError)(error, apiError);
        return rejectWithValue(apiError);
    }
}), {
    condition: (_, { getState }) => {
        const { mobileSessions } = getState();
        return mobileSessions.status !== "PENDING" /* StatusEnum.Pending */;
    },
});
const mobileSessionsSlice = (0, toolkit_1.createSlice)({
    name: "mobileSessions",
    initialState,
    reducers: {
        clearMobileSessions: (state) => {
            state.sessions = [];
            state.fetchableSessionsCount = 0;
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.fetchMobileSessions.pending, (state) => {
            state.status = "PENDING" /* StatusEnum.Pending */;
            state.error = null;
        })
            .addCase(exports.fetchMobileSessions.fulfilled, (state, action) => {
            var _a;
            state.status = "FULFILLED" /* StatusEnum.Fulfilled */;
            state.error = null;
            if ((_a = action.meta) === null || _a === void 0 ? void 0 : _a.arg.isAdditional) {
                state.sessions = [...state.sessions, ...action.payload.sessions];
            }
            else {
                state.sessions = action.payload.sessions;
            }
            state.fetchableSessionsCount = action.payload.fetchableSessionsCount;
        })
            .addCase(exports.fetchMobileSessions.rejected, (state, action) => {
            state.status = "REJECTED" /* StatusEnum.Rejected */;
            state.error = action.payload || {
                message: "An unknown error occurred",
            };
        });
    },
});
exports.clearMobileSessions = mobileSessionsSlice.actions.clearMobileSessions;
exports.default = mobileSessionsSlice.reducer;
