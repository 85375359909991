"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useThresholdHandlers = void 0;
const lodash_1 = require("lodash");
const react_1 = require("react");
const react_i18next_1 = require("react-i18next");
const react_redux_1 = require("react-redux");
const thresholdSlice_1 = require("../store/thresholdSlice");
const useThresholdHandlers = (setThresholdValues, setInputValue, setActiveInput, setErrorMessage, thresholdValues, sliderRef, activeInput, inputValue, sliderWidth) => {
    const inputDebounceTime = 300;
    const { t } = (0, react_i18next_1.useTranslation)();
    const defaultThresholds = (0, react_redux_1.useSelector)(thresholdSlice_1.selectDefaultThresholds);
    const isValueValid = (newValue, min, max) => {
        return newValue >= min && newValue <= max;
    };
    (0, react_1.useEffect)(() => {
        if (activeInput !== null) {
            setInputValue(thresholdValues[activeInput].toString());
        }
    }, [activeInput, thresholdValues, setInputValue]);
    const clearErrorAndUpdateThreshold = (thresholdKey, parsedValue) => {
        setErrorMessage("");
        setThresholdValues((prevValues) => (Object.assign(Object.assign({}, prevValues), { [thresholdKey]: parsedValue })));
    };
    const validateThresholdOrder = (key, value) => {
        const { min, low, middle, high, max } = thresholdValues;
        switch (key) {
            case "low":
                return value < middle;
            case "middle":
                return value > low && value < high;
            case "high":
                return value > middle;
            default:
                return true;
        }
    };
    const handleInputChange = (thresholdKey, value) => {
        const trimmedValue = value.trim();
        if (trimmedValue === "") {
            setInputValue("");
            setErrorMessage(t("thresholdConfigurator.emptyInputMessage"));
            return;
        }
        setInputValue(trimmedValue);
        const parsedValue = Number(trimmedValue);
        if (thresholdKey === "min" || thresholdKey === "max") {
            if (!isValueValid(parsedValue, -Infinity, Infinity)) {
                setErrorMessage(t("thresholdConfigurator.validValueMessage", {
                    minValue: thresholdValues.min,
                    maxValue: thresholdValues.max,
                }));
            }
            else {
                if (thresholdKey === "min" && parsedValue >= thresholdValues.max) {
                    setErrorMessage(t("thresholdConfigurator.minGreaterThanMaxMessage", {
                        maxValue: thresholdValues.max,
                    }));
                }
                else if (thresholdKey === "max" &&
                    parsedValue <= thresholdValues.min) {
                    setErrorMessage(t("thresholdConfigurator.maxLessThanMinMessage", {
                        minValue: thresholdValues.min,
                    }));
                }
                else {
                    clearErrorAndUpdateThreshold(thresholdKey, parsedValue);
                }
            }
        }
        else {
            if (!isValueValid(parsedValue, thresholdValues.min, thresholdValues.max)) {
                setErrorMessage(t("thresholdConfigurator.validValueMessage", {
                    minValue: thresholdValues.min,
                    maxValue: thresholdValues.max,
                }));
            }
            else if (!validateThresholdOrder(thresholdKey, parsedValue)) {
                setErrorMessage(t("thresholdConfigurator.invalidOrderMessage"));
            }
            else {
                clearErrorAndUpdateThreshold(thresholdKey, parsedValue);
            }
        }
    };
    const debouncedHandleInputChange = (0, lodash_1.debounce)(handleInputChange, inputDebounceTime);
    const handleInputBlur = (thresholdKey, inputValue) => {
        debouncedHandleInputChange.flush();
        setActiveInput(null);
        handleInputChange(thresholdKey, inputValue);
    };
    const handleInputFocus = (thresholdKey) => {
        setInputValue(thresholdValues[thresholdKey].toString());
        setActiveInput(thresholdKey);
        debouncedHandleInputChange.cancel();
    };
    const handleOutsideClick = (event) => {
        if (sliderRef.current &&
            !sliderRef.current.contains(event.target) &&
            activeInput !== null) {
            setActiveInput(null);
        }
    };
    const handleInputKeyDown = (thresholdKey) => (event) => {
        if (event.key === "Enter" /* KeyboardKeys.Enter */) {
            setInputValue(event.currentTarget.value);
            handleInputChange(thresholdKey, event.currentTarget.value);
        }
        else if (event.key === "ArrowUp" /* KeyboardKeys.ArrowUp */ ||
            event.key === "ArrowDown" /* KeyboardKeys.ArrowDown */) {
            event.preventDefault();
            let newValue;
            const step = event.shiftKey ? 10 : 1;
            const direction = event.key === "ArrowUp" /* KeyboardKeys.ArrowUp */ ? 1 : -1;
            newValue = parseFloat(inputValue) + step * direction;
            handleInputChange(thresholdKey, newValue.toString());
        }
    };
    const resetThresholds = () => {
        debouncedHandleInputChange.cancel();
        setThresholdValues(defaultThresholds);
        setInputValue("");
        setActiveInput(null);
    };
    const onInputChange = (thresholdKey, value) => {
        setInputValue(value);
        handleInputChange(thresholdKey, value);
    };
    const handleSliderClick = (event) => {
        if (sliderRef.current && activeInput === null) {
            const rect = sliderRef.current.getBoundingClientRect();
            const clientX = "touches" in event ? event.touches[0].clientX : event.clientX;
            const clickX = clientX - rect.left;
            const clickValue = Math.round((clickX / sliderWidth) * (thresholdValues.max - thresholdValues.min) +
                thresholdValues.min);
            const distances = {
                low: Math.abs(clickValue - thresholdValues.low),
                middle: Math.abs(clickValue - thresholdValues.middle),
                high: Math.abs(clickValue - thresholdValues.high),
            };
            const closestThumb = Object.keys(distances).reduce((a, b) => (distances[a] < distances[b] ? a : b));
            if ((closestThumb === "high" && clickValue >= thresholdValues.max) ||
                (closestThumb === "low" && clickValue <= thresholdValues.min)) {
                return;
            }
            const newThresholdValues = Object.assign(Object.assign({}, thresholdValues), { [closestThumb]: clickValue });
            setThresholdValues(newThresholdValues);
        }
    };
    return {
        handleInputChange: onInputChange,
        handleInputBlur,
        handleInputFocus,
        handleInputKeyDown,
        handleOutsideClick,
        resetThresholds,
        handleSliderClick,
    };
};
exports.useThresholdHandlers = useThresholdHandlers;
