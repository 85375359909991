"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThresholdButtonVariant = exports.ThresholdButton = void 0;
const react_1 = __importStar(require("react"));
const S = __importStar(require("./../ThresholdConfigurator.style")); // Import your styled components
const colors = __importStar(require("../../../assets/styles/colors"));
var ThresholdButtonVariant;
(function (ThresholdButtonVariant) {
    ThresholdButtonVariant["IconOnly"] = "iconOnly";
    ThresholdButtonVariant["TextWithIcon"] = "textWithIcon";
})(ThresholdButtonVariant || (exports.ThresholdButtonVariant = ThresholdButtonVariant = {}));
const ThresholdButton = ({ variant = ThresholdButtonVariant.IconOnly, buttonText, swapIconTextPosition = false, useDarkBlueIcon = false, IconComponent, onClick, }) => {
    const iconColor = useDarkBlueIcon ? colors.darkBlue : undefined;
    const buttonTextColor = useDarkBlueIcon ? colors.darkBlue : colors.gray300;
    const buttonContent = (0, react_1.useMemo)(() => {
        if (variant === ThresholdButtonVariant.TextWithIcon) {
            return (react_1.default.createElement(S.ThresholdButtonWrapper, null, swapIconTextPosition ? (react_1.default.createElement(react_1.default.Fragment, null,
                buttonText,
                react_1.default.createElement(IconComponent, { color: iconColor }))) : (react_1.default.createElement(react_1.default.Fragment, null,
                react_1.default.createElement(IconComponent, { color: iconColor }),
                buttonText))));
        }
        return react_1.default.createElement(IconComponent, { color: iconColor });
    }, [variant, swapIconTextPosition, buttonText, IconComponent, iconColor]);
    return (react_1.default.createElement(S.ThresholdButton, { onClick: onClick, style: { color: buttonTextColor }, variant: variant }, buttonContent));
};
exports.ThresholdButton = ThresholdButton;
