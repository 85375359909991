"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setRealtimeMapUpdates = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    realtimeMapUpdates: false,
};
const realtimeMapUpdatesSlice = (0, toolkit_1.createSlice)({
    name: "realtimeMapUpdates",
    initialState,
    reducers: {
        setRealtimeMapUpdates(state, action) {
            state.realtimeMapUpdates = action.payload;
        },
    },
});
exports.setRealtimeMapUpdates = realtimeMapUpdatesSlice.actions.setRealtimeMapUpdates;
exports.default = realtimeMapUpdatesSlice.reducer;
