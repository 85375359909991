"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.urls = void 0;
const habitatMap = "https://habitatmap.org";
const android = "https://play.google.com/store/apps/details?id=pl.llp.aircasting&hl=en_US";
const iOS = "https://apps.apple.com/us/app/aircasting/id1587685281#?platform=iphone";
const actions = "https://aircastingactions.org/";
// Top level
const about = `${habitatMap}/about`;
const airbeam = `${habitatMap}/airbeam`;
const aircasting = `${habitatMap}/aircasting`;
const map = "/";
const blog = `${habitatMap}/blog`;
const donate = `${habitatMap}/donate`;
const press = `${habitatMap}/about/press`;
const search = `${habitatMap}/search`;
const reactMap = `/`;
// Airbeam
const userStories = `${airbeam}/user-stories`;
const airbeamHowItWorks = `${airbeam}/how-it-works`;
const faq = `${airbeam}/FAQ`;
const usersGuide = `${airbeam}/users-guide`;
const airbeamBuyNow = `${airbeam}/buy-it-now`;
const history = `${about}/history`;
exports.urls = {
    habitatMap,
    android,
    iOS,
    actions,
    about,
    airbeam,
    aircasting,
    map,
    blog,
    donate,
    press,
    search,
    userStories,
    airbeamHowItWorks,
    faq,
    usersGuide,
    airbeamBuyNow,
    history,
    reactMap,
};
