"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SensorPrefix = exports.BasicSensorTypes = exports.SENSOR_NAMES = void 0;
exports.SENSOR_NAMES = {
    PARTICULATE_MATTER: {
        AIRBEAM_PM25: "AirBeam-PM2.5",
        AIRBEAM_PM10: "AirBeam-PM10",
        AIRBEAM_PM1: "AirBeam-PM1",
        GOVERNMENT_PM25: "Government-PM2.5",
        AIRBEAM_MINI_PM25: "AirBeamMini-PM2.5",
        AIRBEAM_MINI_PM1: "AirBeamMini-PM1",
    },
    HUMIDITY: "AirBeam-RH",
    NITROGEN_DIOXIDE: "Government-NO2",
    OZONE: "Government-Ozone",
    TEMPERATURE: "AirBeam-F",
    SOUND_LEVEL: "Phone Microphone",
};
exports.BasicSensorTypes = {
    HUMIDITY: exports.SENSOR_NAMES.HUMIDITY,
    NITROGEN_DIOXIDE: exports.SENSOR_NAMES.NITROGEN_DIOXIDE,
    OZONE: exports.SENSOR_NAMES.OZONE,
    PARTICULATE_MATTER: {
        MOBILE: [
            exports.SENSOR_NAMES.PARTICULATE_MATTER.AIRBEAM_PM25,
            exports.SENSOR_NAMES.PARTICULATE_MATTER.AIRBEAM_PM10,
            exports.SENSOR_NAMES.PARTICULATE_MATTER.AIRBEAM_PM1,
        ],
        FIXED: [
            exports.SENSOR_NAMES.PARTICULATE_MATTER.AIRBEAM_PM25,
            exports.SENSOR_NAMES.PARTICULATE_MATTER.AIRBEAM_PM10,
            exports.SENSOR_NAMES.PARTICULATE_MATTER.AIRBEAM_PM1,
            exports.SENSOR_NAMES.PARTICULATE_MATTER.GOVERNMENT_PM25,
        ],
    },
    SOUND_LEVEL: exports.SENSOR_NAMES.SOUND_LEVEL,
    TEMPERATURE: exports.SENSOR_NAMES.TEMPERATURE,
};
exports.SensorPrefix = {
    AIR: "Air",
    GOVERNMENT: "Government",
};
