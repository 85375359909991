"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_google_maps_1 = require("@vis.gl/react-google-maps");
const react_1 = require("react");
const colors_1 = require("../../../../assets/styles/colors");
const CustomMarker_1 = require("../CustomMarker");
const HoverMarker = ({ position }) => {
    const map = (0, react_google_maps_1.useMap)();
    const markerRef = (0, react_1.useRef)(null);
    const HOVER_MARKER_Z_INDEX = 3;
    (0, react_1.useEffect)(() => {
        if (!map || !position)
            return;
        if (!markerRef.current) {
            markerRef.current = new CustomMarker_1.CustomMarker(position, `${colors_1.blue}`, "Hover Marker", 16, undefined, undefined, 20, "floatPane");
            markerRef.current.setMap(map);
            markerRef.current.setZIndex(HOVER_MARKER_Z_INDEX);
        }
        else {
            markerRef.current.setPosition(position);
            markerRef.current.setZIndex(HOVER_MARKER_Z_INDEX);
        }
        return () => {
            if (markerRef.current) {
                markerRef.current.setMap(null);
                markerRef.current = null;
            }
        };
    }, [map, position]);
    return null;
};
exports.default = HoverMarker;
