"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectIsLoading = exports.selectMobileSessionsLoading = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
// Add selectors for each relevant slice
const selectFixedStreamLoading = (state) => state.fixedStream.status === "PENDING" /* StatusEnum.Pending */;
const selectFixedSessionsLoading = (state) => state.fixedSessions.status === "PENDING" /* StatusEnum.Pending */;
const selectMobileSessionsLoading = (state) => state.mobileSessions.status === "PENDING" /* StatusEnum.Pending */;
exports.selectMobileSessionsLoading = selectMobileSessionsLoading;
const selectMobileStreamLoading = (state) => state.mobileStream.status === "PENDING" /* StatusEnum.Pending */;
const selectTimelapseLoading = (state) => state.timelapse.status === "PENDING" /* StatusEnum.Pending */;
const selectCrowdMapLoading = (state) => state.crowdMap.status === "PENDING" /* StatusEnum.Pending */;
// Combine loading states
exports.selectIsLoading = (0, toolkit_1.createSelector)([
    selectFixedStreamLoading,
    selectFixedSessionsLoading,
    exports.selectMobileSessionsLoading,
    selectMobileStreamLoading,
    selectTimelapseLoading,
    selectCrowdMapLoading,
], (fixedStreamLoading, fixedSessionsLoading, mobileSessionsLoading, mobileStreamLoading, timelapseLoading, crowdMapLoading) => fixedStreamLoading ||
    fixedSessionsLoading ||
    mobileSessionsLoading ||
    mobileStreamLoading ||
    timelapseLoading ||
    crowdMapLoading);
