"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectIsLoading = exports.selectFixedData = exports.resetFixedStreamState = exports.updateFixedMeasurementExtremes = exports.fetchMeasurements = exports.fetchFixedStreamById = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const apiClient_1 = require("../api/apiClient");
const apiEndpoints_1 = require("../api/apiEndpoints");
const getErrorMessage_1 = require("../utils/getErrorMessage");
const logController_1 = require("../utils/logController");
const initialState = {
    data: {
        stream: {
            title: "",
            profile: "",
            lastUpdate: "",
            sensorName: "",
            unitSymbol: "",
            updateFrequency: "",
            active: true,
            sessionId: 0,
            startTime: "",
            endTime: "",
            min: 0,
            low: 0,
            middle: 0,
            high: 0,
            max: 0,
            latitude: 0,
            longitude: 0,
        },
        measurements: [],
        streamDailyAverages: [],
    },
    minMeasurementValue: 0,
    maxMeasurementValue: 0,
    averageMeasurementValue: 0,
    status: "IDLE" /* StatusEnum.Idle */,
    error: null,
    isLoading: false,
};
exports.fetchFixedStreamById = (0, toolkit_1.createAsyncThunk)("fixedStream/getData", (id, { rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield apiClient_1.apiClient.get(apiEndpoints_1.API_ENDPOINTS.fetchFixedStreamById(id));
        return response.data;
    }
    catch (error) {
        const message = (0, getErrorMessage_1.getErrorMessage)(error);
        const apiError = {
            message,
            additionalInfo: {
                action: "fetchFixedStreamById",
                endpoint: apiEndpoints_1.API_ENDPOINTS.fetchFixedStreamById(id),
            },
        };
        (0, logController_1.logError)(error, apiError);
        return rejectWithValue(apiError);
    }
}));
exports.fetchMeasurements = (0, toolkit_1.createAsyncThunk)("measurements/getData", ({ streamId, startTime, endTime }, { rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield apiClient_1.oldApiClient.get(apiEndpoints_1.API_ENDPOINTS.fetchMeasurements(streamId, startTime, endTime));
        return response.data;
    }
    catch (error) {
        const message = (0, getErrorMessage_1.getErrorMessage)(error);
        const apiError = {
            message,
            additionalInfo: {
                action: "fetchFixedStreamById",
                endpoint: apiEndpoints_1.API_ENDPOINTS.fetchMeasurements(streamId, startTime, endTime),
            },
        };
        (0, logController_1.logError)(error, apiError);
        return rejectWithValue(apiError);
    }
}));
const fixedStreamSlice = (0, toolkit_1.createSlice)({
    name: "fixedStream",
    initialState,
    reducers: {
        updateFixedMeasurementExtremes(state, action) {
            const { min, max } = action.payload;
            const measurementsInRange = state.data.measurements.filter((measurement) => {
                const time = measurement.time;
                return time >= min && time <= max;
            });
            const values = measurementsInRange.map((m) => m.value);
            const newMin = Math.min(...values);
            const newMax = Math.max(...values);
            const newAvg = values.reduce((sum, value) => sum + value, 0) / values.length;
            state.minMeasurementValue = newMin;
            state.maxMeasurementValue = newMax;
            state.averageMeasurementValue = newAvg;
        },
        resetFixedStreamState(state) {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(exports.fetchFixedStreamById.pending, (state) => {
            state.status = "PENDING" /* StatusEnum.Pending */;
            state.error = null;
            state.isLoading = true;
        });
        builder.addCase(exports.fetchFixedStreamById.fulfilled, (state, action) => {
            state.status = "FULFILLED" /* StatusEnum.Fulfilled */;
            state.data = action.payload;
            state.isLoading = false;
            state.error = null;
        });
        builder.addCase(exports.fetchFixedStreamById.rejected, (state, action) => {
            state.status = "REJECTED" /* StatusEnum.Rejected */;
            state.error = action.payload || { message: "Unknown error occurred" };
            state.data = initialState.data;
            state.isLoading = false;
        });
        builder.addCase(exports.fetchMeasurements.pending, (state) => {
            state.status = "PENDING" /* StatusEnum.Pending */;
            state.error = null;
            state.isLoading = true;
        });
        builder.addCase(exports.fetchMeasurements.fulfilled, (state, action) => {
            state.status = "FULFILLED" /* StatusEnum.Fulfilled */;
            state.data.measurements = action.payload;
            state.isLoading = false;
            state.error = null;
            if (action.payload.length > 0) {
                const values = action.payload.map((m) => m.value);
                state.minMeasurementValue = Math.min(...values);
                state.maxMeasurementValue = Math.max(...values);
                state.averageMeasurementValue =
                    values.reduce((sum, value) => sum + value, 0) / values.length;
            }
            else {
                state.minMeasurementValue = 0;
                state.maxMeasurementValue = 0;
                state.averageMeasurementValue = 0;
            }
        });
        builder.addCase(exports.fetchMeasurements.rejected, (state, action) => {
            state.status = "REJECTED" /* StatusEnum.Rejected */;
            state.error = action.payload || { message: "Unknown error occurred" };
            state.data = initialState.data;
            state.isLoading = false;
        });
    },
});
exports.default = fixedStreamSlice.reducer;
_a = fixedStreamSlice.actions, exports.updateFixedMeasurementExtremes = _a.updateFixedMeasurementExtremes, exports.resetFixedStreamState = _a.resetFixedStreamState;
const selectFixedData = (state) => state.fixedStream.data;
exports.selectFixedData = selectFixedData;
const selectIsLoading = (state) => state.fixedStream.isLoading;
exports.selectIsLoading = selectIsLoading;
