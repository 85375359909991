"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.remove = exports.getJSON = exports.get = exports.set = void 0;
// Set a cookie with optional parameters
const set = (name, value, options = {}) => {
    options = Object.assign({ path: "/" }, options);
    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }
    let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);
    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }
    document.cookie = updatedCookie;
};
exports.set = set;
// Get a cookie by name
const get = (name) => {
    const matches = document.cookie.match(new RegExp("(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"));
    return matches ? decodeURIComponent(matches[1]) : null;
};
exports.get = get;
// Parse JSON cookie
const getJSON = (name) => {
    const value = (0, exports.get)(name);
    return value ? JSON.parse(value) : null;
};
exports.getJSON = getJSON;
// Delete a cookie
const remove = (name) => {
    (0, exports.set)(name, "", {
        "max-age": -1,
    });
};
exports.remove = remove;
