"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserSettings = void 0;
var UserSettings;
(function (UserSettings) {
    UserSettings["CalendarView"] = "CALENDAR_VIEW";
    UserSettings["CrowdMapView"] = "CROWD_MAP_VIEW";
    UserSettings["FiltersView"] = "FILTERS_VIEW";
    UserSettings["MapLegendView"] = "MAP_LEGEND_VIEW";
    UserSettings["MapView"] = "MAP_VIEW";
    UserSettings["ModalView"] = "MODAL_VIEW";
    UserSettings["SessionListView"] = "SESSION_LIST_VIEW";
    UserSettings["TimelapseView"] = "TIMELAPSE_VIEW";
})(UserSettings || (exports.UserSettings = UserSettings = {}));
