"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.exportSessionSlice = exports.exportSession = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const apiClient_1 = require("../api/apiClient");
const apiEndpoints_1 = require("../api/apiEndpoints");
const getErrorMessage_1 = require("../utils/getErrorMessage");
const logController_1 = require("../utils/logController");
const initialState = {
    data: {
        sessionsIds: [],
        email: "",
    },
    status: "IDLE" /* StatusEnum.Idle */,
    error: null,
};
exports.exportSession = (0, toolkit_1.createAsyncThunk)("session/exportSession", (sessionData, { rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield apiClient_1.oldApiClient.get(apiEndpoints_1.API_ENDPOINTS.exportSessionData(sessionData.sessionsIds, sessionData.email));
        return response.data;
    }
    catch (error) {
        const message = (0, getErrorMessage_1.getErrorMessage)(error);
        const apiError = {
            message,
            additionalInfo: {
                action: "exportSession",
                endpoint: apiEndpoints_1.API_ENDPOINTS.exportSessionData(sessionData.sessionsIds, sessionData.email),
            },
        };
        (0, logController_1.logError)(error, apiError);
        return rejectWithValue(apiError);
    }
}));
exports.exportSessionSlice = (0, toolkit_1.createSlice)({
    name: "session",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(exports.exportSession.fulfilled, (state, action) => {
            state.status = "FULFILLED" /* StatusEnum.Fulfilled */;
            state.data = action.payload;
            state.error = null;
        });
        builder.addCase(exports.exportSession.rejected, (state, action) => {
            state.status = "REJECTED" /* StatusEnum.Rejected */;
            state.error = action.payload || { message: "Unknown error occurred" };
            state.data = initialState.data;
        });
    },
});
exports.default = exports.exportSessionSlice.reducer;
