"use strict";
"use client";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MobileMarkers = void 0;
const react_google_maps_1 = require("@vis.gl/react-google-maps");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const hooks_1 = require("../../../store/hooks");
const markersLoadingSlice_1 = require("../../../store/markersLoadingSlice");
const mobileStreamSelectors_1 = require("../../../store/mobileStreamSelectors");
const thresholdSlice_1 = require("../../../store/thresholdSlice");
const mapParamsHandler_1 = require("../../../utils/mapParamsHandler");
const thresholdColors_1 = require("../../../utils/thresholdColors");
const CustomMarker_1 = require("./CustomMarker");
const customMarkerLabel_1 = require("./customMarkerLabel");
const customMarkerOverlay_1 = require("./customMarkerOverlay");
const MobileMarkers = ({ sessions, onMarkerClick, selectedStreamId, pulsatingSessionId, }) => {
    const DISTANCE_THRESHOLD = 21;
    const ZOOM_FOR_SELECTED_SESSION = 16;
    const LAT_DIFF_SMALL = 0.00001;
    const LAT_DIFF_MEDIUM = 0.0001;
    const LAT_ADJUST_SMALL = 0.005;
    const BASE_Z_INDEX = 1;
    const OVERLAY_Z_INDEX = 2;
    const map = (0, react_google_maps_1.useMap)();
    const dispatch = (0, hooks_1.useAppDispatch)();
    const thresholds = (0, react_redux_1.useSelector)(thresholdSlice_1.selectThresholds);
    const { unitSymbol } = (0, mapParamsHandler_1.useMapParams)();
    const mobileStreamData = (0, react_redux_1.useSelector)(mobileStreamSelectors_1.selectMobileStreamData);
    const mobileStreamStatus = (0, react_redux_1.useSelector)(mobileStreamSelectors_1.selectMobileStreamStatus);
    const markerRefs = (0, react_1.useRef)(new Map());
    const markerOverlays = (0, react_1.useRef)(new Map());
    const labelOverlays = (0, react_1.useRef)(new Map());
    const [selectedMarkerKey, setSelectedMarkerKey] = (0, react_1.useState)(null);
    const areMarkersTooClose = (0, react_1.useCallback)((marker1, marker2) => {
        var _a;
        if (!map)
            return false;
        const zoom = (_a = map.getZoom()) !== null && _a !== void 0 ? _a : 0;
        const latDiff = marker1.lat - marker2.lat;
        const lngDiff = marker1.lng - marker2.lng;
        const distance = Math.sqrt(latDiff * latDiff + lngDiff * lngDiff);
        const pixelSize = Math.pow(2, -zoom);
        const distanceInPixels = distance / pixelSize;
        return distanceInPixels < DISTANCE_THRESHOLD;
    }, [map]);
    const centerMapOnBounds = (0, react_1.useCallback)((minLatitude, maxLatitude, minLongitude, maxLongitude) => {
        if (map && !selectedMarkerKey) {
            const latDiff = maxLatitude - minLatitude;
            const lngDiff = maxLongitude - minLongitude;
            if (latDiff < LAT_DIFF_SMALL && lngDiff < LAT_DIFF_SMALL) {
                const centerLat = (maxLatitude + minLatitude) / 2;
                const centerLng = (maxLongitude + minLongitude) / 2;
                map.setCenter({ lat: centerLat, lng: centerLng });
                map.setZoom(ZOOM_FOR_SELECTED_SESSION);
            }
            else {
                let adjustedLat;
                if (latDiff >= 0 && latDiff < LAT_DIFF_SMALL) {
                    adjustedLat = minLatitude - LAT_ADJUST_SMALL;
                }
                else if (latDiff >= LAT_DIFF_SMALL && latDiff < LAT_DIFF_MEDIUM) {
                    adjustedLat = minLatitude - latDiff * 2;
                }
                else {
                    adjustedLat = minLatitude - latDiff;
                }
                const bounds = new google.maps.LatLngBounds(new google.maps.LatLng(adjustedLat, minLongitude), new google.maps.LatLng(maxLatitude, maxLongitude));
                map.fitBounds(bounds);
                if (latDiff === 0) {
                    map.setZoom(ZOOM_FOR_SELECTED_SESSION);
                }
            }
            setSelectedMarkerKey(null);
        }
    }, [map, selectedMarkerKey]);
    const centerMapOnMarker = (0, react_1.useCallback)((position) => {
        if (map && !selectedMarkerKey) {
            map.setCenter(position);
            map.setZoom(ZOOM_FOR_SELECTED_SESSION);
        }
        setSelectedMarkerKey(null);
    }, [map, selectedMarkerKey]);
    const createMarker = (0, react_1.useCallback)((session) => {
        const color = (0, thresholdColors_1.getColorForValue)(thresholds, session.lastMeasurementValue);
        const shouldPulse = session.id === pulsatingSessionId;
        const size = 12;
        const marker = new CustomMarker_1.CustomMarker(session.point, color, "", size, undefined, () => {
            onMarkerClick(Number(session.point.streamId), Number(session.id));
            centerMapOnMarker(session.point);
        }, size, "overlayMouseTarget");
        marker.setPulsating(shouldPulse);
        marker.setZIndex(BASE_Z_INDEX);
        return marker;
    }, [thresholds, pulsatingSessionId, onMarkerClick, centerMapOnMarker]);
    const updateMarkers = (0, react_1.useCallback)(() => {
        markerRefs.current.forEach((marker, streamId) => {
            const session = sessions.find((s) => s.point.streamId === streamId);
            if (!session)
                return;
            const isSelected = streamId === (selectedStreamId === null || selectedStreamId === void 0 ? void 0 : selectedStreamId.toString());
            const shouldPulse = session.id === pulsatingSessionId;
            const isOverlapping = sessions.some((otherSession) => otherSession.point.streamId !== streamId &&
                areMarkersTooClose(session.point, otherSession.point));
            const color = (0, thresholdColors_1.getColorForValue)(thresholds, session.lastMeasurementValue);
            const size = 12;
            marker.setColor(color);
            marker.setSize(size);
            marker.setPulsating(shouldPulse);
            marker.setClickableAreaSize(size);
            marker.setZIndex(BASE_Z_INDEX);
            if (isOverlapping) {
                const existingOverlay = markerOverlays.current.get(streamId);
                if (existingOverlay) {
                    existingOverlay.setMap(null);
                    markerOverlays.current.delete(streamId);
                }
                const existingLabel = labelOverlays.current.get(streamId);
                if (existingLabel) {
                    existingLabel.setMap(null);
                    labelOverlays.current.delete(streamId);
                }
            }
            else {
                let overlay = markerOverlays.current.get(streamId);
                if (!overlay) {
                    overlay = new customMarkerOverlay_1.CustomMarkerOverlay(marker.getPosition(), color, isSelected, shouldPulse);
                    overlay.setMap(map);
                    markerOverlays.current.set(streamId, overlay);
                }
                else {
                    overlay.setIsSelected(isSelected);
                    overlay.setShouldPulse(shouldPulse);
                    overlay.setColor(color);
                    overlay.update();
                }
                let labelOverlay = labelOverlays.current.get(streamId);
                if (!labelOverlay) {
                    labelOverlay = new customMarkerLabel_1.LabelOverlay(marker.getPosition(), color, session.lastMeasurementValue, unitSymbol, isSelected, () => {
                        onMarkerClick(Number(streamId), Number(session.id));
                        centerMapOnMarker(session.point);
                    });
                    labelOverlay.setMap(map);
                    labelOverlays.current.set(streamId, labelOverlay);
                }
                else {
                    labelOverlay.update(isSelected, color, session.lastMeasurementValue, unitSymbol);
                }
                labelOverlay.setZIndex(OVERLAY_Z_INDEX);
            }
        });
    }, [
        sessions,
        selectedStreamId,
        pulsatingSessionId,
        thresholds,
        unitSymbol,
        areMarkersTooClose,
        map,
        onMarkerClick,
        centerMapOnMarker,
    ]);
    (0, react_1.useEffect)(() => {
        if (selectedStreamId && mobileStreamStatus !== "PENDING" /* StatusEnum.Pending */) {
            const { minLatitude, maxLatitude, minLongitude, maxLongitude } = mobileStreamData;
            if (minLatitude && maxLatitude && minLongitude && maxLongitude) {
                centerMapOnBounds(minLatitude, maxLatitude, minLongitude, maxLongitude);
            }
        }
        if (selectedStreamId === null) {
            setSelectedMarkerKey(null);
        }
    }, [
        selectedStreamId,
        mobileStreamData,
        mobileStreamStatus,
        centerMapOnBounds,
    ]);
    (0, react_1.useEffect)(() => {
        if (!selectedStreamId) {
            dispatch((0, markersLoadingSlice_1.setMarkersLoading)(true));
        }
    }, [dispatch, sessions.length, selectedStreamId]);
    (0, react_1.useEffect)(() => {
        if (!map)
            return;
        const updatedMarkers = new Set();
        sessions.forEach((session) => {
            const markerId = session.point.streamId;
            updatedMarkers.add(markerId);
            let marker = markerRefs.current.get(markerId);
            if (!marker) {
                marker = createMarker(session);
                marker.setMap(map);
                markerRefs.current.set(markerId, marker);
            }
            else {
                marker.setPosition(session.point);
            }
        });
        markerRefs.current.forEach((marker, markerId) => {
            if (!updatedMarkers.has(markerId)) {
                marker.setMap(null);
                markerRefs.current.delete(markerId);
                const overlay = markerOverlays.current.get(markerId);
                if (overlay) {
                    overlay.setMap(null);
                    markerOverlays.current.delete(markerId);
                }
                const labelOverlay = labelOverlays.current.get(markerId);
                if (labelOverlay) {
                    labelOverlay.setMap(null);
                    labelOverlays.current.delete(markerId);
                }
            }
        });
        updateMarkers();
        if (!selectedStreamId && markerRefs.current.size >= sessions.length) {
            dispatch((0, markersLoadingSlice_1.setMarkersLoading)(false));
        }
    }, [sessions, map, createMarker, updateMarkers, selectedStreamId, dispatch]);
    (0, react_1.useEffect)(() => {
        return () => {
            markerRefs.current.forEach((marker) => marker.setMap(null));
            markerRefs.current.clear();
            markerOverlays.current.forEach((overlay) => overlay.setMap(null));
            markerOverlays.current.clear();
            labelOverlays.current.forEach((overlay) => overlay.setMap(null));
            labelOverlays.current.clear();
        };
    }, []);
    return null;
};
exports.MobileMarkers = MobileMarkers;
