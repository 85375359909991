"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useScrollEndListener = void 0;
const react_1 = require("react");
const useScrollEndListener = (elementRef, onScrollEnd) => {
    (0, react_1.useEffect)(() => {
        const listInnerElement = elementRef.current;
        if (listInnerElement) {
            const onScroll = () => {
                const { scrollTop, scrollHeight, clientHeight } = listInnerElement;
                const isNearBottom = scrollTop + clientHeight >= scrollHeight - 10;
                if (isNearBottom) {
                    onScrollEnd();
                }
            };
            listInnerElement.addEventListener("scroll", onScroll);
            return () => {
                listInnerElement.removeEventListener("scroll", onScroll);
            };
        }
    }, [elementRef, onScrollEnd]);
};
exports.useScrollEndListener = useScrollEndListener;
