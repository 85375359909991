"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectIndoorSessionsStatusFulfilled = exports.selectIndoorSessionsState = exports.selectIndoorSessionsPoints = exports.selectIndoorSessionsList = void 0;
const reselect_1 = require("reselect");
const selectIndoorSessionsState = (state) => state.indoorSessions;
exports.selectIndoorSessionsState = selectIndoorSessionsState;
const selectIndoorSessionsStatusFulfilled = (state) => state.indoorSessions.status === "FULFILLED" /* StatusEnum.Fulfilled */;
exports.selectIndoorSessionsStatusFulfilled = selectIndoorSessionsStatusFulfilled;
const selectIndoorSessionsPoints = (isDormant) => (0, reselect_1.createSelector)([selectIndoorSessionsState], (indoorSessionsState) => {
    const sessions = isDormant
        ? indoorSessionsState.dormantIndoorSessions
        : indoorSessionsState.activeIndoorSessions;
    return sessions.map(({ id, lastMeasurementValue, title, startTimeLocal, endTimeLocal, streams, }) => {
        const firstStream = streams[Object.keys(streams)[0]];
        return {
            id,
            title,
            sensorName: firstStream.sensorName,
            startTime: startTimeLocal,
            endTime: endTimeLocal,
            lastMeasurementValue,
        };
    });
});
exports.selectIndoorSessionsPoints = selectIndoorSessionsPoints;
const selectIndoorSessionsList = (isDormant) => (0, reselect_1.createSelector)([selectIndoorSessionsState], (indoorSessionsState) => {
    const sessions = isDormant
        ? indoorSessionsState.dormantIndoorSessions
        : indoorSessionsState.activeIndoorSessions;
    return sessions.map(({ id, title, startTimeLocal, endTimeLocal, streams }) => {
        const firstStream = streams[Object.keys(streams)[0]];
        return {
            id,
            title,
            sensorName: firstStream.sensorName,
            averageValue: firstStream.streamDailyAverage,
            startTime: startTimeLocal,
            endTime: endTimeLocal,
            streamId: firstStream.id,
        };
    });
});
exports.selectIndoorSessionsList = selectIndoorSessionsList;
