"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectThumbPositions = exports.selectSliderWidth = exports.selectUserThresholds = exports.selectThresholds = exports.selectDefaultThresholds = exports.clearErrorMessage = exports.updateThumbPositions = exports.updateSliderWidth = exports.setUserThresholdValues = exports.setDefaultThresholdsValues = exports.resetUserThresholds = exports.thresholdSlice = exports.fetchThresholds = exports.initialState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const apiClient_1 = require("../api/apiClient");
const apiEndpoints_1 = require("../api/apiEndpoints");
const getErrorMessage_1 = require("../utils/getErrorMessage");
const logController_1 = require("../utils/logController");
exports.initialState = {
    defaultValues: {
        min: 0,
        low: 0,
        middle: 0,
        high: 0,
        max: 0,
    },
    status: "IDLE" /* StatusEnum.Idle */,
    sliderWidth: 0,
    thumbPositions: {
        low: 0,
        middle: 0,
        high: 0,
    },
    errorMessage: "",
    error: null,
};
exports.fetchThresholds = (0, toolkit_1.createAsyncThunk)("thresholds/getData", (filters, { rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield apiClient_1.oldApiClient.get(apiEndpoints_1.API_ENDPOINTS.fetchThresholds(filters));
        return response.data;
    }
    catch (error) {
        const message = (0, getErrorMessage_1.getErrorMessage)(error);
        const apiError = {
            message,
            additionalInfo: {
                action: "fetchThresholds",
                endpoint: apiEndpoints_1.API_ENDPOINTS.fetchThresholds(filters),
            },
        };
        (0, logController_1.logError)(error, apiError);
        return rejectWithValue(apiError);
    }
}));
exports.thresholdSlice = (0, toolkit_1.createSlice)({
    name: "threshold",
    initialState: Object.assign({}, exports.initialState),
    extraReducers: (builder) => {
        builder
            .addCase(exports.fetchThresholds.pending, (state) => {
            state.status = "PENDING" /* StatusEnum.Pending */;
            state.error = null;
        })
            .addCase(exports.fetchThresholds.fulfilled, (state, action) => {
            state.status = "FULFILLED" /* StatusEnum.Fulfilled */;
            state.error = null;
            state.defaultValues.min = Number(action.payload[0]);
            state.defaultValues.low = Number(action.payload[1]);
            state.defaultValues.middle = Number(action.payload[2]);
            state.defaultValues.high = Number(action.payload[3]);
            state.defaultValues.max = Number(action.payload[4]);
            if (!state.userValues) {
                state.userValues = Object.assign({}, state.defaultValues);
            }
        })
            .addCase(exports.fetchThresholds.rejected, (state, action) => {
            state.status = "REJECTED" /* StatusEnum.Rejected */;
            state.error = action.payload || {
                message: "An unknown error occurred",
            };
        });
    },
    reducers: {
        resetUserThresholds: (state) => {
            state.userValues = Object.assign({}, state.defaultValues);
        },
        setDefaultThresholdsValues: (state, { payload: { min, low, middle, high, max } }) => {
            state.defaultValues = { min, low, middle, high, max };
        },
        setUserThresholdValues: (state, { payload: { min, low, middle, high, max } }) => {
            state.userValues = { min, low, middle, high, max };
        },
        updateSliderWidth: (state, action) => {
            state.sliderWidth = action.payload;
        },
        updateThumbPositions: (state, action) => {
            state.thumbPositions = action.payload;
        },
        clearErrorMessage: (state) => {
            state.errorMessage = "";
        },
    },
});
_a = exports.thresholdSlice.actions, exports.resetUserThresholds = _a.resetUserThresholds, exports.setDefaultThresholdsValues = _a.setDefaultThresholdsValues, exports.setUserThresholdValues = _a.setUserThresholdValues, exports.updateSliderWidth = _a.updateSliderWidth, exports.updateThumbPositions = _a.updateThumbPositions, exports.clearErrorMessage = _a.clearErrorMessage;
exports.default = exports.thresholdSlice.reducer;
const selectDefaultThresholds = (state) => state.threshold.defaultValues;
exports.selectDefaultThresholds = selectDefaultThresholds;
const selectThresholds = (state) => state.threshold.userValues || state.threshold.defaultValues;
exports.selectThresholds = selectThresholds;
const selectUserThresholds = (state) => state.threshold.userValues;
exports.selectUserThresholds = selectUserThresholds;
const selectSliderWidth = (state) => state.threshold.sliderWidth;
exports.selectSliderWidth = selectSliderWidth;
const selectThumbPositions = (state) => state.threshold.thumbPositions;
exports.selectThumbPositions = selectThumbPositions;
