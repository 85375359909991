"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchDormantIndoorSessions = exports.fetchActiveIndoorSessions = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const apiClient_1 = require("../api/apiClient");
const apiEndpoints_1 = require("../api/apiEndpoints");
const getErrorMessage_1 = require("../utils/getErrorMessage");
const logController_1 = require("../utils/logController");
const sessionFiltersSlice_1 = require("./sessionFiltersSlice");
const initialState = {
    fetchableSessionsCount: 0,
    activeIndoorSessions: [],
    dormantIndoorSessions: [],
    status: "IDLE" /* StatusEnum.Idle */,
    error: null,
};
const createSessionFetchThunk = (type, endpoint) => {
    return (0, toolkit_1.createAsyncThunk)(`sessions/fetch${type.charAt(0).toUpperCase() + type.slice(1)}FixedSessions`, (sessionsData, { rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            const response = yield apiClient_1.oldApiClient.get(endpoint(sessionsData.filters));
            return response.data;
        }
        catch (error) {
            const message = (0, getErrorMessage_1.getErrorMessage)(error);
            const apiError = {
                message,
                additionalInfo: {
                    action: `fetch${type.charAt(0).toUpperCase() + type.slice(1)}FixedSessions`,
                    endpoint: endpoint(sessionsData.filters),
                },
            };
            (0, logController_1.logError)(error, apiError);
            return rejectWithValue(apiError);
        }
    }), {
        condition: (_, { getState }) => {
            const { fixedSessions } = getState();
            return fixedSessions.status !== "PENDING" /* StatusEnum.Pending */;
        },
    });
};
exports.fetchActiveIndoorSessions = createSessionFetchThunk(sessionFiltersSlice_1.FixedSessionsTypes.ACTIVE, apiEndpoints_1.API_ENDPOINTS.fetchActiveFixedSessions);
exports.fetchDormantIndoorSessions = createSessionFetchThunk(sessionFiltersSlice_1.FixedSessionsTypes.DORMANT, apiEndpoints_1.API_ENDPOINTS.fetchDormantFixedSessions);
const indoorSessionsSlice = (0, toolkit_1.createSlice)({
    name: "indoorSessions",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(exports.fetchActiveIndoorSessions.pending, (state) => {
            state.status = "PENDING" /* StatusEnum.Pending */;
            state.error = null;
        })
            .addCase(exports.fetchActiveIndoorSessions.fulfilled, (state, action) => {
            state.status = "FULFILLED" /* StatusEnum.Fulfilled */;
            state.activeIndoorSessions = action.payload.sessions;
            state.fetchableSessionsCount = action.payload.fetchableSessionsCount;
            state.error = null;
        })
            .addCase(exports.fetchActiveIndoorSessions.rejected, (state, action) => {
            state.status = "REJECTED" /* StatusEnum.Rejected */;
            state.error = action.payload || { message: "Unknown error occurred" };
        })
            .addCase(exports.fetchDormantIndoorSessions.pending, (state) => {
            state.status = "PENDING" /* StatusEnum.Pending */;
            state.error = null;
        })
            .addCase(exports.fetchDormantIndoorSessions.fulfilled, (state, action) => {
            state.status = "FULFILLED" /* StatusEnum.Fulfilled */;
            state.dormantIndoorSessions = action.payload.sessions;
            state.fetchableSessionsCount = action.payload.fetchableSessionsCount;
            state.error = null;
        })
            .addCase(exports.fetchDormantIndoorSessions.rejected, (state, action) => {
            state.status = "REJECTED" /* StatusEnum.Rejected */;
            state.error = action.payload || { message: "Unknown error occurred" };
        });
    },
});
exports.default = indoorSessionsSlice.reducer;
