"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectParameters = exports.selectSensors = exports.fetchSensors = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const apiClient_1 = require("../api/apiClient");
const apiEndpoints_1 = require("../api/apiEndpoints");
const getErrorMessage_1 = require("../utils/getErrorMessage");
const logController_1 = require("../utils/logController");
const initialState = {
    sensors: [],
    fetchSensorsStatus: "IDLE" /* StatusEnum.Idle */,
    error: null,
};
exports.fetchSensors = (0, toolkit_1.createAsyncThunk)("sensors", (sessionType, { rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    const sessionTypeCapitalized = sessionType[0].toUpperCase() + sessionType.slice(1);
    try {
        const response = yield apiClient_1.oldApiClient.get(apiEndpoints_1.API_ENDPOINTS.fetchSensors(sessionTypeCapitalized));
        return response.data;
    }
    catch (error) {
        const message = (0, getErrorMessage_1.getErrorMessage)(error);
        const apiError = {
            message,
            additionalInfo: {
                action: "fetchSensors",
                endpoint: apiEndpoints_1.API_ENDPOINTS.fetchSensors(sessionTypeCapitalized),
            },
        };
        (0, logController_1.logError)(error, apiError);
        return rejectWithValue(apiError);
    }
}));
const sensorsSlice = (0, toolkit_1.createSlice)({
    name: "sensors",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(exports.fetchSensors.pending, (state) => {
            state.fetchSensorsStatus = "PENDING" /* StatusEnum.Pending */;
            state.error = null;
        })
            .addCase(exports.fetchSensors.fulfilled, (state, action) => {
            state.fetchSensorsStatus = "FULFILLED" /* StatusEnum.Fulfilled */;
            state.sensors = action.payload;
            state.error = null;
        })
            .addCase(exports.fetchSensors.rejected, (state, action) => {
            state.fetchSensorsStatus = "REJECTED" /* StatusEnum.Rejected */;
            state.error = action.payload || {
                message: "Unknown error occurred",
            };
        });
    },
});
const selectSensors = (state) => state.sensors.sensors;
exports.selectSensors = selectSensors;
exports.selectParameters = (0, toolkit_1.createSelector)([exports.selectSensors], (sensors) => {
    const parameters = sensors.map((sensor) => sensor.measurementType);
    const parametersUnique = parameters.filter((parameter, index) => parameters.indexOf(parameter) === index);
    return parametersUnique;
});
exports.default = sensorsSlice.reducer;
