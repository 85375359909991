"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setVisibility = exports.fetchClusterData = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const apiClient_1 = require("../api/apiClient");
const apiEndpoints_1 = require("../api/apiEndpoints");
const getErrorMessage_1 = require("../utils/getErrorMessage");
const logController_1 = require("../utils/logController");
const initialState = {
    data: null,
    loading: false,
    error: null,
    visible: false,
};
exports.fetchClusterData = (0, toolkit_1.createAsyncThunk)("cluster/fetchClusterData", (streamIds, { rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield apiClient_1.oldApiClient.get(apiEndpoints_1.API_ENDPOINTS.fetchClusterData(streamIds));
        return response.data;
    }
    catch (error) {
        const errorMessage = (0, getErrorMessage_1.getErrorMessage)(error);
        const apiError = {
            message: errorMessage,
            additionalInfo: {
                action: "fetchClusterData",
                endpoint: apiEndpoints_1.API_ENDPOINTS.fetchClusterData(streamIds),
            },
        };
        (0, logController_1.logError)(error, apiError);
        return rejectWithValue(apiError);
    }
}));
const clusterSlice = (0, toolkit_1.createSlice)({
    name: "cluster",
    initialState,
    reducers: {
        setVisibility: (state, action) => {
            state.visible = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.fetchClusterData.pending, (state) => {
            state.loading = true;
            state.error = null;
            state.visible = true;
        })
            .addCase(exports.fetchClusterData.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
            state.visible = true;
        })
            .addCase(exports.fetchClusterData.rejected, (state, action) => {
            state.error = action.payload || {
                message: "An unknown error occurred",
            };
            state.loading = false;
            state.visible = false;
        });
    },
});
exports.setVisibility = clusterSlice.actions.setVisibility;
exports.default = clusterSlice.reducer;
