"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ControlPanel = void 0;
const react_1 = __importStar(require("react"));
const react_i18next_1 = require("react-i18next");
const react_router_dom_1 = require("react-router-dom");
const map_1 = require("../../../types/map");
const mapParamsHandler_1 = require("../../../utils/mapParamsHandler");
const CheckboxButton_1 = require("../../CheckboxButton/CheckboxButton");
const Toggle_1 = require("../../Toggle/Toggle");
const S = __importStar(require("./ControlPanel.style"));
const ControlPanel = () => {
    const { mapTypeId, searchParams } = (0, mapParamsHandler_1.useMapParams)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const isFirstRender = (0, react_1.useRef)(true);
    const { t } = (0, react_i18next_1.useTranslation)();
    const [viewMode, setViewMode] = (0, react_1.useState)(map_1.ViewMode.MAP);
    const [isTerrainChecked, setIsTerrainChecked] = (0, react_1.useState)(false);
    const [isLabelsChecked, setIsLabelsChecked] = (0, react_1.useState)(false);
    const updateURLParams = (param, value) => {
        const newSearchParams = new URLSearchParams(searchParams);
        newSearchParams.set(param, value);
        navigate({ search: newSearchParams.toString() }, { replace: true });
    };
    (0, react_1.useEffect)(() => {
        if (mapTypeId) {
            switch (mapTypeId) {
                case map_1.MapTypeId.SATELLITE:
                    setViewMode(map_1.ViewMode.SATELLITE);
                    setIsTerrainChecked(false);
                    setIsLabelsChecked(false);
                    break;
                case map_1.MapTypeId.HYBRID:
                    setViewMode(map_1.ViewMode.SATELLITE);
                    setIsTerrainChecked(false);
                    setIsLabelsChecked(true);
                    break;
                case map_1.MapTypeId.TERRAIN:
                    setViewMode(map_1.ViewMode.MAP);
                    setIsTerrainChecked(true);
                    setIsLabelsChecked(false);
                    break;
                default:
                    setViewMode(map_1.ViewMode.MAP);
                    setIsTerrainChecked(false);
                    setIsLabelsChecked(false);
            }
        }
    }, []);
    (0, react_1.useEffect)(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        }
        else {
            updateURLParams(mapParamsHandler_1.UrlParamsTypes.mapType, mapTypeId);
        }
    }, [mapTypeId]);
    const handleViewModeChange = (newViewMode) => {
        let selectedMapTypeId = map_1.MapTypeId.ROADMAP;
        switch (newViewMode) {
            case map_1.ViewMode.SATELLITE:
                selectedMapTypeId = map_1.MapTypeId.HYBRID;
                setIsLabelsChecked(true);
                break;
            case map_1.ViewMode.MAP:
                selectedMapTypeId = isTerrainChecked
                    ? map_1.MapTypeId.TERRAIN
                    : map_1.MapTypeId.ROADMAP;
                setIsLabelsChecked(false);
                break;
        }
        setViewMode(newViewMode);
        updateURLParams(mapParamsHandler_1.UrlParamsTypes.mapType, selectedMapTypeId);
    };
    const handleTerrainChange = (isChecked) => {
        const newMapTypeId = isChecked ? map_1.MapTypeId.TERRAIN : map_1.MapTypeId.ROADMAP;
        setIsTerrainChecked(isChecked);
        if (viewMode === map_1.ViewMode.MAP) {
            updateURLParams(mapParamsHandler_1.UrlParamsTypes.mapType, newMapTypeId);
        }
    };
    const handleLabelsChange = (isChecked) => {
        const newMapTypeId = isChecked ? map_1.MapTypeId.HYBRID : map_1.MapTypeId.SATELLITE;
        setIsLabelsChecked(isChecked);
        if (viewMode === map_1.ViewMode.SATELLITE) {
            updateURLParams(mapParamsHandler_1.UrlParamsTypes.mapType, newMapTypeId);
        }
    };
    return (react_1.default.createElement(S.ControlPanelContainer, null,
        react_1.default.createElement(S.ToggleContainer, null,
            react_1.default.createElement(Toggle_1.Toggle, { isChecked: viewMode === map_1.ViewMode.SATELLITE, onChange: (isChecked) => handleViewModeChange(isChecked ? map_1.ViewMode.SATELLITE : map_1.ViewMode.MAP), labelLeft: t("map.mapLabel"), labelRight: t("map.satelliteLabel"), variant: "switch" })),
        viewMode === map_1.ViewMode.MAP && (react_1.default.createElement(S.TerrainContainer, null,
            react_1.default.createElement(CheckboxButton_1.CheckboxButton, { label: t("map.terrainLabel"), isChecked: isTerrainChecked, onChange: handleTerrainChange }))),
        viewMode === map_1.ViewMode.SATELLITE && (react_1.default.createElement(S.TerrainContainer, null,
            react_1.default.createElement(CheckboxButton_1.CheckboxButton, { label: t("map.labelsLabel"), isChecked: isLabelsChecked, onChange: handleLabelsChange }))),
        react_1.default.createElement(S.SelectContainer, null,
            react_1.default.createElement(S.Select, { value: mapTypeId, onChange: (e) => {
                    const selectedMapTypeId = e.target.value;
                    updateURLParams(mapParamsHandler_1.UrlParamsTypes.mapType, selectedMapTypeId);
                } },
                react_1.default.createElement("option", { value: map_1.MapTypeId.ROADMAP }, t("map.mapViewLabel")),
                react_1.default.createElement("option", { value: map_1.MapTypeId.SATELLITE }, t("map.mapSatelliteLabel")),
                react_1.default.createElement("option", { value: map_1.MapTypeId.HYBRID }, t("map.mapHybridLabel")),
                react_1.default.createElement("option", { value: map_1.MapTypeId.TERRAIN }, t("map.mapTerrainLabel"))))));
};
exports.ControlPanel = ControlPanel;
