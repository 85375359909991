"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const S = __importStar(require("./Graph.style"));
const TimeRange = (0, react_1.forwardRef)(({ minTime, maxTime }, ref) => {
    const [isInitialized, setIsInitialized] = (0, react_1.useState)(false);
    const [isEmpty, setIsEmpty] = (0, react_1.useState)(true);
    (0, react_1.useEffect)(() => {
        if (ref && "current" in ref && ref.current) {
            setIsEmpty(ref.current.childElementCount === 0);
            setIsInitialized(true);
        }
    }, []);
    return (react_1.default.createElement(S.TimeRangeContainer, { ref: ref }, isInitialized && isEmpty && (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(S.TimeContainer, null,
            react_1.default.createElement(S.Date, null, minTime.date),
            react_1.default.createElement(S.Time, null, minTime.time)),
        "-",
        react_1.default.createElement(S.TimeContainer, null,
            react_1.default.createElement(S.Date, null, maxTime.date),
            react_1.default.createElement(S.Time, null, maxTime.time))))));
});
exports.default = TimeRange;
