"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SubNavTitleLink = exports.SubNav = exports.NavList = exports.NavItemLink = exports.NavHeader = exports.ListItem = exports.Link = exports.GoBack = exports.Container = exports.BuyCTAWhite = exports.Button = exports.BottomNavContainer = exports.AircastingLogo = void 0;
const styled_components_1 = __importStar(require("styled-components"));
const colors_1 = require("../../../assets/styles/colors");
const media_1 = require("../../../utils/media");
const Navbar_style_1 = require("../Navbar.style");
const AircastingLogo = styled_components_1.default.img `
  width: 152px;
  height: 43px;
`;
exports.AircastingLogo = AircastingLogo;
const Button = styled_components_1.default.button `
  border: none;
  background: none;
  cursor: pointer;
`;
exports.Button = Button;
const Container = styled_components_1.default.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
exports.Container = Container;
const NavList = styled_components_1.default.ul `
  background: ${colors_1.theme};
  list-style-type: none;
  font-weight: 400;
  font-family: Moderat, sans-serif;
  padding: 2.5rem 3.2rem;
  width: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: ${(p) => (p.$isVisible ? "0" : "-35.2rem")};
  z-index: 7;
  -webkit-font-smoothing: antialiased;
  line-height: 1.5;
  letter-spacing: 0.5px;
  overflow: scroll;

  ${(p) => p.$isVisible &&
    (0, styled_components_1.css) `
      right: 0;
      opacity: 1;
      transition: right 0.5s ease-out, opacity 0.5s ease-out;
    `}

  @media ${media_1.media.smallDesktop} {
    min-width: 35.2rem;
    width: auto;
    ${(p) => p.$isVisible &&
    (0, styled_components_1.css) `
        right: 0;
        transition: right 0.3s ease;
      `}
  }
`;
exports.NavList = NavList;
const SubNav = styled_components_1.default.ul `
  padding: 0.8rem 0 1.6rem 0;
  border-bottom: 1px solid ${colors_1.white};
  list-style-type: none;
`;
exports.SubNav = SubNav;
const NavHeader = styled_components_1.default.li `
  display: flex;
  justify-content: space-between;
  padding-bottom: 2.45rem;
  border-bottom: 1px solid ${colors_1.white};
`;
exports.NavHeader = NavHeader;
const ListItem = styled_components_1.default.li `
  border-bottom: none;
  opacity: ${(p) => (p.$isActive ? "0.5" : "1")};
`;
exports.ListItem = ListItem;
const SubNavTitleLink = styled_components_1.default.a `
  color: ${colors_1.white};
  font-size: 2.2rem;
  display: inline-block;
  padding: 0.5rem 0;
  text-decoration: none;

  &:hover {
    opacity: 0.5;
  }

  @media ${media_1.media.smallDesktop} {
    font-size: 1.8rem;
  }
`;
exports.SubNavTitleLink = SubNavTitleLink;
const NavItemLink = (0, styled_components_1.default)(SubNavTitleLink) `
  font-size: 1.9rem;
  padding: 0.5rem 0;

  @media ${media_1.media.smallDesktop} {
    font-size: 1.5rem;
  }
`;
exports.NavItemLink = NavItemLink;
const Link = (0, styled_components_1.default)(NavItemLink) `
  text-transform: uppercase;
  font-size: 1.3rem;
`;
exports.Link = Link;
const BottomNavContainer = (0, styled_components_1.default)(Container) `
  border-top: 1px solid ${colors_1.white};
  padding-top: 3.2rem;
`;
exports.BottomNavContainer = BottomNavContainer;
const BuyCTAWhite = (0, styled_components_1.default)(Navbar_style_1.BuyCTA) `
  background-color: transparent;
  border: 1px solid ${colors_1.white};
  color: ${colors_1.white};
  border-radius: 0px;
  font-weight: 400;
  padding: 1.8rem 2rem;
  font-size: 1.3rem;

  &:visited,
  &:link,
  &:active {
    color: ${colors_1.white};
  }

  &:hover {
    background-color: ${colors_1.white};
    color: ${colors_1.blue};
  }
`;
exports.BuyCTAWhite = BuyCTAWhite;
const GoBack = styled_components_1.default.a `
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.3rem;
  color: ${colors_1.darkBlue};
  display: flex;
  align-items: center;
  gap: 15px;
  line-height: 22px;
`;
exports.GoBack = GoBack;
