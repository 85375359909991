"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectRectangleLoading = exports.selectRectangleData = exports.clearRectangles = exports.fetchRectangleData = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const apiClient_1 = require("../api/apiClient");
const apiEndpoints_1 = require("../api/apiEndpoints");
const getErrorMessage_1 = require("../utils/getErrorMessage");
const logController_1 = require("../utils/logController");
const initialState = {
    loading: false,
    error: null,
};
exports.fetchRectangleData = (0, toolkit_1.createAsyncThunk)("rectangle/fetchRectangleData", (params, { rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield apiClient_1.oldApiClient.get(apiEndpoints_1.API_ENDPOINTS.fetchRectangleData(params));
        return response.data;
    }
    catch (error) {
        const message = (0, getErrorMessage_1.getErrorMessage)(error);
        const apiError = {
            message,
            additionalInfo: {
                action: "fetchRectangleData",
                endpoint: apiEndpoints_1.API_ENDPOINTS.fetchRectangleData(params),
            },
        };
        (0, logController_1.logError)(error, apiError);
        return rejectWithValue(apiError);
    }
}));
const rectangleSlice = (0, toolkit_1.createSlice)({
    name: "rectangle",
    initialState,
    reducers: {
        clearRectangles: (state) => {
            state.data = undefined;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.fetchRectangleData.pending, (state) => {
            state.loading = true;
            state.error = null;
        })
            .addCase(exports.fetchRectangleData.fulfilled, (state, action) => {
            state.data = action.payload;
            state.loading = false;
            state.error = null;
        })
            .addCase(exports.fetchRectangleData.rejected, (state, action) => {
            state.error = action.payload || {
                message: "An unknown error occurred",
            };
            state.loading = false;
        });
    },
});
exports.clearRectangles = rectangleSlice.actions.clearRectangles;
exports.default = rectangleSlice.reducer;
const selectRectangleData = (state) => state.rectangle.data;
exports.selectRectangleData = selectRectangleData;
const selectRectangleLoading = (state) => state.rectangle.loading;
exports.selectRectangleLoading = selectRectangleLoading;
