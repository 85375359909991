"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateThumbPosition = exports.calculateThumbPercentage = void 0;
const calculateThumbPercentage = (value, min, max) => {
    const percentage = (value - min) / (max - min);
    return percentage;
};
exports.calculateThumbPercentage = calculateThumbPercentage;
const calculateThumbPosition = (value, min, max, width, isMobile) => {
    const percentage = calculateThumbPercentage(value, min, max);
    // added 20 because the slider starts at the center of the first thumb and the calculations are off for desktop
    //TODO: look into slider width calculations and thumb placements to fix without "-20"
    const sliderCorrectWidth = isMobile ? width : width - 20;
    return percentage * sliderCorrectWidth;
};
exports.calculateThumbPosition = calculateThumbPosition;
