"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateMinMaxValues = exports.calculateUniformThresholds = void 0;
const calculateUniformThresholds = (min, max) => {
    const step = Math.max(Math.floor((max - min) / 4), 1);
    const lowThreshold = min + step;
    const middleThreshold = lowThreshold + step;
    const highThreshold = middleThreshold + step;
    const maxThreshold = Math.max(highThreshold + step, max);
    return {
        min,
        low: lowThreshold,
        middle: middleThreshold,
        high: highThreshold,
        max: maxThreshold,
    };
};
exports.calculateUniformThresholds = calculateUniformThresholds;
const calculateMinMaxValues = (mobileStream, fixedStream, sessionId) => {
    if (mobileStream.status === "FULFILLED" /* StatusEnum.Fulfilled */ &&
        mobileStream.data.id === sessionId) {
        const min = Math.floor(mobileStream.minMeasurementValue);
        const max = Math.ceil(mobileStream.maxMeasurementValue);
        return { min, max };
    }
    else if (fixedStream.status === "FULFILLED" /* StatusEnum.Fulfilled */ &&
        fixedStream.data.stream.sessionId === sessionId) {
        const min = Math.floor(fixedStream.minMeasurementValue);
        const max = Math.ceil(fixedStream.maxMeasurementValue);
        return { min, max };
    }
    else {
        console.warn("No stream data available or session mismatch");
        return null;
    }
};
exports.calculateMinMaxValues = calculateMinMaxValues;
