"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectFetchingCrowdMapData = exports.selectCrowdMapRectangles = exports.setFetchingCrowdMapData = exports.clearCrowdMap = exports.fetchCrowdMapData = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const apiClient_1 = require("../api/apiClient");
const apiEndpoints_1 = require("../api/apiEndpoints");
const getErrorMessage_1 = require("../utils/getErrorMessage");
const logController_1 = require("../utils/logController");
const initialState = {
    fetchingData: true,
    rectangles: [],
    status: "IDLE" /* StatusEnum.Idle */,
    error: null,
};
exports.fetchCrowdMapData = (0, toolkit_1.createAsyncThunk)("crowdMap/getCrowdMapData", (filters, { rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield apiClient_1.oldApiClient.get(apiEndpoints_1.API_ENDPOINTS.fetchCrowdMap(filters));
        return response.data;
    }
    catch (error) {
        const message = (0, getErrorMessage_1.getErrorMessage)(error);
        const apiError = {
            message,
            additionalInfo: {
                action: "fetchCrowdMapData",
                endpoint: apiEndpoints_1.API_ENDPOINTS.fetchCrowdMap(filters),
            },
        };
        (0, logController_1.logError)(error, apiError);
        return rejectWithValue(apiError);
    }
}));
const crowdMapSlice = (0, toolkit_1.createSlice)({
    name: "crowdMap",
    initialState,
    reducers: {
        clearCrowdMap: (state) => {
            state.rectangles = [];
        },
        setFetchingCrowdMapData(state, action) {
            state.fetchingData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(exports.fetchCrowdMapData.pending, (state) => {
            state.status = "PENDING" /* StatusEnum.Pending */;
            state.error = null;
        })
            .addCase(exports.fetchCrowdMapData.fulfilled, (state, action) => {
            state.status = "FULFILLED" /* StatusEnum.Fulfilled */;
            state.rectangles = action.payload;
            state.fetchingData = false;
            state.error = null;
        })
            .addCase(exports.fetchCrowdMapData.rejected, (state, action) => {
            state.status = "REJECTED" /* StatusEnum.Rejected */;
            state.error = action.payload || { message: "Unknown error occurred" };
            state.fetchingData = false;
        });
    },
});
_a = crowdMapSlice.actions, exports.clearCrowdMap = _a.clearCrowdMap, exports.setFetchingCrowdMapData = _a.setFetchingCrowdMapData;
exports.default = crowdMapSlice.reducer;
const selectCrowdMapRectangles = (state) => state.crowdMap.rectangles;
exports.selectCrowdMapRectangles = selectCrowdMapRectangles;
const selectFetchingCrowdMapData = (state) => state.crowdMap.fetchingData;
exports.selectFetchingCrowdMapData = selectFetchingCrowdMapData;
