"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectSessionsListExpanded = exports.selectHoverPosition = exports.selectHoverStreamId = exports.selectFetchingData = exports.setSessionsListExpanded = exports.setMapId = exports.setHoverStreamId = exports.setHoverPosition = exports.setFetchingData = exports.initialState = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const mapConfigs_1 = require("../components/Map/mapConfigs");
const coordinates_1 = require("../const/coordinates");
exports.initialState = {
    fetchingData: true,
    hoverStreamId: null,
    mapId: mapConfigs_1.MAP_ID,
    position: coordinates_1.DEFAULT_MAP_CENTER,
    sessionsListExpanded: true,
};
const mapSlice = (0, toolkit_1.createSlice)({
    name: "map",
    initialState: exports.initialState,
    reducers: {
        setFetchingData(state, action) {
            state.fetchingData = action.payload;
        },
        setHoverStreamId(state, action) {
            state.hoverStreamId = action.payload;
        },
        setHoverPosition(state, action) {
            state.position = action.payload;
        },
        setMapId(state, action) {
            state.mapId = action.payload;
        },
        setSessionsListExpanded(state, action) {
            state.sessionsListExpanded = action.payload;
        },
    },
});
_a = mapSlice.actions, exports.setFetchingData = _a.setFetchingData, exports.setHoverPosition = _a.setHoverPosition, exports.setHoverStreamId = _a.setHoverStreamId, exports.setMapId = _a.setMapId, exports.setSessionsListExpanded = _a.setSessionsListExpanded;
exports.default = mapSlice.reducer;
const selectFetchingData = (state) => state.map.fetchingData;
exports.selectFetchingData = selectFetchingData;
const selectHoverStreamId = (state) => state.map.hoverStreamId;
exports.selectHoverStreamId = selectHoverStreamId;
const selectHoverPosition = (state) => state.map.position;
exports.selectHoverPosition = selectHoverPosition;
const selectSessionsListExpanded = (state) => state.map.sessionsListExpanded;
exports.selectSessionsListExpanded = selectSessionsListExpanded;
