"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UnitSymbols = exports.SessionTypes = exports.ParameterTypes = exports.MobileBasicParameterTypes = exports.FixedBasicParameterTypes = void 0;
const SessionTypes = {
    FIXED: "fixed",
    MOBILE: "mobile",
};
exports.SessionTypes = SessionTypes;
const ParameterTypes = {
    HUMIDITY: "Humidity",
    NITROGEN_DIOXIDE: "Nitrogen Dioxide",
    OZONE: "Ozone",
    PARTICULATE_MATTER: "Particulate Matter",
    SOUND_LEVEL: "Sound Level",
    TEMPERATURE: "Temperature",
};
exports.ParameterTypes = ParameterTypes;
const MobileBasicParameterTypes = [
    ParameterTypes.HUMIDITY,
    ParameterTypes.PARTICULATE_MATTER,
    ParameterTypes.SOUND_LEVEL,
    ParameterTypes.TEMPERATURE,
];
exports.MobileBasicParameterTypes = MobileBasicParameterTypes;
const FixedBasicParameterTypes = [
    ParameterTypes.HUMIDITY,
    ParameterTypes.NITROGEN_DIOXIDE,
    ParameterTypes.OZONE,
    ParameterTypes.PARTICULATE_MATTER,
    ParameterTypes.TEMPERATURE,
];
exports.FixedBasicParameterTypes = FixedBasicParameterTypes;
const UnitSymbols = {
    ParticulateMatter: "µg/m³",
};
exports.UnitSymbols = UnitSymbols;
