"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTimelapseTimeRange = exports.setCurrentTimestamp = exports.fetchTimelapseData = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const apiClient_1 = require("../api/apiClient");
const apiEndpoints_1 = require("../api/apiEndpoints");
const timelapse_1 = require("../types/timelapse");
const getErrorMessage_1 = require("../utils/getErrorMessage");
const logController_1 = require("../utils/logController");
const initialState = {
    data: {},
    status: "IDLE" /* StatusEnum.Idle */,
    error: null,
    isLoading: false,
    currentTimestamp: null,
    timelapseTimeRange: timelapse_1.TimeRanges.HOURS_24,
};
exports.fetchTimelapseData = (0, toolkit_1.createAsyncThunk)("timelapse/fetchData", (sessionsData, { rejectWithValue }) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const response = yield apiClient_1.apiClient.get(apiEndpoints_1.API_ENDPOINTS.fetchTimelapseData(sessionsData.filters));
        return response.data;
    }
    catch (error) {
        const message = (0, getErrorMessage_1.getErrorMessage)(error);
        const apiError = {
            message,
            additionalInfo: {
                action: "fetchTimelapseData",
                endpoint: apiEndpoints_1.API_ENDPOINTS.fetchTimelapseData(sessionsData.filters),
            },
        };
        (0, logController_1.logError)(error, apiError);
        return rejectWithValue(apiError);
    }
}), {
    condition: (_, { getState }) => {
        const { timelapse } = getState();
        if (timelapse.status === "PENDING" /* StatusEnum.Pending */) {
            return false;
        }
    },
});
const timelapseSlice = (0, toolkit_1.createSlice)({
    name: "timelapse",
    initialState,
    reducers: {
        setCurrentTimestamp(state, action) {
            state.currentTimestamp = action.payload;
        },
        setTimelapseTimeRange(state, action) {
            state.timelapseTimeRange = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(exports.fetchTimelapseData.pending, (state) => {
            state.status = "PENDING" /* StatusEnum.Pending */;
            state.isLoading = true;
            state.error = null;
        });
        builder.addCase(exports.fetchTimelapseData.fulfilled, (state, action) => {
            state.status = "FULFILLED" /* StatusEnum.Fulfilled */;
            state.data = action.payload;
            state.isLoading = false;
            const timestamps = Object.keys(action.payload);
            if (timestamps.length > 0) {
                state.currentTimestamp = timestamps[timestamps.length - 1];
            }
        });
        builder.addCase(exports.fetchTimelapseData.rejected, (state, action) => {
            state.status = "REJECTED" /* StatusEnum.Rejected */;
            state.error = action.payload || {
                message: "An unknown error occurred",
            };
            state.isLoading = false;
        });
    },
});
_a = timelapseSlice.actions, exports.setCurrentTimestamp = _a.setCurrentTimestamp, exports.setTimelapseTimeRange = _a.setTimelapseTimeRange;
exports.default = timelapseSlice.reducer;
