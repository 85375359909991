"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectMobileSessionsStreamIds = exports.selectMobileSessionsState = exports.selectMobileSessionsPoints = exports.selectMobileSessionsList = exports.selectMobileSessionPointsBySessionId = void 0;
const reselect_1 = require("reselect");
const selectMobileSessionsState = (state) => state.mobileSessions;
exports.selectMobileSessionsState = selectMobileSessionsState;
const selectMobileSessionPointsBySessionId = (sessionId) => (0, reselect_1.createSelector)([selectMobileSessionsState], (mobileSessionState) => {
    const mobileSessionByStreamId = mobileSessionState.sessions.find((session) => Number(session.id) === Number(sessionId));
    const stream = mobileSessionByStreamId === null || mobileSessionByStreamId === void 0 ? void 0 : mobileSessionByStreamId.streams[Object.keys(mobileSessionByStreamId === null || mobileSessionByStreamId === void 0 ? void 0 : mobileSessionByStreamId.streams)[0]];
    return [
        {
            id: (mobileSessionByStreamId === null || mobileSessionByStreamId === void 0 ? void 0 : mobileSessionByStreamId.id) || 0,
            lastMeasurementValue: (stream === null || stream === void 0 ? void 0 : stream.averageValue) || 0,
            point: {
                lat: (stream === null || stream === void 0 ? void 0 : stream.startLatitude) || 0,
                lng: (stream === null || stream === void 0 ? void 0 : stream.startLongitude) || 0,
                maxLatitude: stream === null || stream === void 0 ? void 0 : stream.maxLatitude,
                maxLongitude: stream === null || stream === void 0 ? void 0 : stream.maxLongitude,
                minLatitude: stream === null || stream === void 0 ? void 0 : stream.minLatitude,
                minLongitude: stream === null || stream === void 0 ? void 0 : stream.minLongitude,
                streamId: (stream === null || stream === void 0 ? void 0 : stream.id.toString()) || "0",
            },
        },
    ];
});
exports.selectMobileSessionPointsBySessionId = selectMobileSessionPointsBySessionId;
const selectMobileSessionsPoints = (0, reselect_1.createSelector)([selectMobileSessionsState], (mobileSessionsState) => mobileSessionsState.sessions.map(({ id, streams }) => {
    const firstStream = streams[Object.keys(streams)[0]];
    return {
        id,
        lastMeasurementValue: firstStream.averageValue,
        point: {
            lat: firstStream.startLatitude,
            lng: firstStream.startLongitude,
            maxLatitude: firstStream.maxLatitude,
            maxLongitude: firstStream.maxLongitude,
            minLatitude: firstStream.minLatitude,
            minLongitude: firstStream.minLongitude,
            streamId: firstStream.id.toString(),
        },
    };
}));
exports.selectMobileSessionsPoints = selectMobileSessionsPoints;
const selectMobileSessionsList = (0, reselect_1.createSelector)([selectMobileSessionsState], (mobileSessionsState) => mobileSessionsState.sessions.map(({ id, title, startTimeLocal, endTimeLocal, streams }) => {
    const firstStream = streams[Object.keys(streams)[0]];
    return {
        id,
        title,
        sensorName: firstStream.sensorName,
        averageValue: firstStream.averageValue,
        startTime: startTimeLocal,
        endTime: endTimeLocal,
        streamId: firstStream.id,
    };
}));
exports.selectMobileSessionsList = selectMobileSessionsList;
const selectMobileSessionsStreamIds = (0, reselect_1.createSelector)([selectMobileSessionsState], (mobileSessionsState) => mobileSessionsState.sessions.map(({ id, streams }) => {
    const firstStream = streams[Object.keys(streams)[0]];
    return firstStream.id;
}));
exports.selectMobileSessionsStreamIds = selectMobileSessionsStreamIds;
