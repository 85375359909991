"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.YearPickerButtons = exports.getLastFiveYears = exports.endOfTheYear = exports.beginningOfTheYear = void 0;
const react_1 = __importStar(require("react"));
const hooks_1 = require("../../store/hooks");
const mapSlice_1 = require("../../store/mapSlice");
const mapParamsHandler_1 = require("../../utils/mapParamsHandler");
const S = __importStar(require("./SessionFilters.style"));
const beginningOfTheYear = (year) => new Date(`${year}-01-01T00:00:00Z`).getTime() / 1000;
exports.beginningOfTheYear = beginningOfTheYear;
const endOfTheYear = (year) => new Date(`${year}-12-31T23:59:59Z`).getTime() / 1000;
exports.endOfTheYear = endOfTheYear;
const getLastFiveYears = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = 0; i < 5; i++) {
        years.push(currentYear - i);
    }
    return years;
};
exports.getLastFiveYears = getLastFiveYears;
const YearPickerButtons = () => {
    const dispatch = (0, hooks_1.useAppDispatch)();
    const { timeFrom, updateTime } = (0, mapParamsHandler_1.useMapParams)();
    const handleYear = (0, react_1.useCallback)((year) => {
        updateTime(year);
        dispatch((0, mapSlice_1.setFetchingData)(true));
    }, [dispatch, updateTime]);
    const timestampToYear = (timestamp) => {
        const date = new Date(Number(timestamp) * 1000);
        return date.getUTCFullYear();
    };
    return (react_1.default.createElement(S.SectionButtonsContainer, null, (0, exports.getLastFiveYears)().map((year) => (react_1.default.createElement(S.SectionButton, { key: year, onClick: () => handleYear(year), "$isActive": timestampToYear(timeFrom) === year }, year)))));
};
exports.YearPickerButtons = YearPickerButtons;
