"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewMode = exports.MapTypeId = void 0;
const MapTypeId = {
    ROADMAP: 'roadmap',
    SATELLITE: 'satellite',
    TERRAIN: 'terrain',
    HYBRID: 'hybrid'
};
exports.MapTypeId = MapTypeId;
var ViewMode;
(function (ViewMode) {
    ViewMode["MAP"] = "map";
    ViewMode["SATELLITE"] = "satellite";
    ViewMode["TERRAIN"] = "terrain";
    ViewMode["LABELS"] = "labels";
})(ViewMode || (exports.ViewMode = ViewMode = {}));
