"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const react_i18next_1 = require("react-i18next");
const fixedStreamSelectors_1 = require("../../store/fixedStreamSelectors");
const hooks_1 = require("../../store/hooks");
const thresholdSlice_1 = require("../../store/thresholdSlice");
const measurementsCalc_1 = require("../../utils/measurementsCalc");
const thresholdColors_1 = require("../../utils/thresholdColors");
const S = __importStar(require("../Modals/SessionDetailsModal/SessionDetailsModal.style"));
const Graph_style_1 = require("./Graph.style");
const MeasurementComponent = () => {
    const { t } = (0, react_i18next_1.useTranslation)();
    const extremes = (0, hooks_1.useAppSelector)(fixedStreamSelectors_1.selectFixedExtremes);
    const thresholds = (0, hooks_1.useAppSelector)(thresholdSlice_1.selectThresholds);
    const { unitSymbol } = (0, hooks_1.useAppSelector)(fixedStreamSelectors_1.selectFixedStreamShortInfo);
    const { minMeasurementValue, maxMeasurementValue, averageValue } = extremes;
    const averageValueColor = (0, react_1.useMemo)(() => (0, thresholdColors_1.getColorForValue)(thresholds, averageValue), [thresholds, averageValue]);
    const minMeasurementValueColor = (0, react_1.useMemo)(() => (0, thresholdColors_1.getColorForValue)(thresholds, minMeasurementValue), [thresholds, minMeasurementValue]);
    const maxMeasurementValueColor = (0, react_1.useMemo)(() => (0, thresholdColors_1.getColorForValue)(thresholds, maxMeasurementValue), [thresholds, maxMeasurementValue]);
    const noData = (0, measurementsCalc_1.isNoData)(extremes.minMeasurementValue, extremes.maxMeasurementValue, extremes.averageValue);
    return (react_1.default.createElement(Graph_style_1.MeasurementContainer, null, noData ? (react_1.default.createElement(S.NoData, null, t("sessionDetailsModal.noData"))) : (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(S.AverageValueContainer, null,
            react_1.default.createElement(S.AverageDot, { "$color": averageValueColor }),
            t("sessionDetailsModal.averageValue"),
            react_1.default.createElement(S.AverageValue, null, averageValue),
            unitSymbol),
        react_1.default.createElement(S.MinMaxValueContainer, { "$isMobile": false },
            react_1.default.createElement("div", null,
                react_1.default.createElement(S.SmallDot, { "$color": minMeasurementValueColor }),
                t("sessionDetailsModal.minValue"),
                react_1.default.createElement(S.Value, null, minMeasurementValue)),
            react_1.default.createElement("div", null,
                react_1.default.createElement(S.SmallDot, { "$color": maxMeasurementValueColor }),
                t("sessionDetailsModal.maxValue"),
                react_1.default.createElement(S.Value, null, maxMeasurementValue)))))));
};
exports.default = MeasurementComponent;
