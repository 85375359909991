"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.handleTouchStart = exports.handleMouseDown = exports.handleTouchMove = exports.handleMouseUp = exports.handleMouseMove = void 0;
const thresholdThumbCalculations_1 = require("./thresholdThumbCalculations");
const handleMouseMove = (thresholdKey, startX, startValue, thresholdValues, sliderWidth, setThresholdValues, setInputValue) => (moveEvent) => {
    // How much the thumb has moved horizontally since drag started.
    const displacement = moveEvent.clientX - startX;
    // Threshold new percentage, based on thumb value when dragging started,
    // the displacement, and slider width.
    const newPercentage = (0, thresholdThumbCalculations_1.calculateThumbPercentage)(startValue, thresholdValues.min, thresholdValues.max) +
        displacement / sliderWidth;
    // Threshold new value based on the threshold new percentage.
    let newThresholdValue = Math.round(thresholdValues.min +
        newPercentage * (thresholdValues.max - thresholdValues.min));
    // Ensure the value is within min and max bounds.
    const newThresholdValueWithinBounds = Math.min(Math.max(newThresholdValue, thresholdValues.min), thresholdValues.max);
    let updatedValues = Object.assign({}, thresholdValues);
    let currentValue = newThresholdValueWithinBounds;
    currentValue = Math.max(currentValue, thresholdValues.min);
    currentValue = Math.min(currentValue, thresholdValues.max);
    setInputValue(currentValue.toString());
    switch (thresholdKey) {
        case "low":
            if (currentValue >= thresholdValues.middle &&
                thresholdValues.middle !== thresholdValues.max) {
                updatedValues.middle = Math.min(currentValue + 1, thresholdValues.max);
            }
            if (currentValue >= thresholdValues.high &&
                thresholdValues.high !== thresholdValues.max) {
                updatedValues.high = Math.min(currentValue + 2, thresholdValues.max);
            }
            break;
        case "middle":
            if (currentValue <= thresholdValues.low &&
                thresholdValues.low !== thresholdValues.min) {
                updatedValues.low = Math.max(currentValue - 1, thresholdValues.min);
            }
            if (currentValue > thresholdValues.high &&
                thresholdValues.high !== thresholdValues.max) {
                updatedValues.high = Math.min(currentValue + 1, thresholdValues.max);
            }
            break;
        case "high":
            if (currentValue <= thresholdValues.middle &&
                thresholdValues.middle !== thresholdValues.min) {
                updatedValues.middle = Math.max(currentValue - 1, thresholdValues.min);
            }
            if (currentValue <= thresholdValues.low &&
                thresholdValues.low !== thresholdValues.min) {
                updatedValues.low = Math.max(currentValue - 2, thresholdValues.min);
            }
            break;
    }
    updatedValues[thresholdKey] = currentValue;
    setThresholdValues(updatedValues);
};
exports.handleMouseMove = handleMouseMove;
const handleMouseUp = (moveHandler) => () => {
    document.removeEventListener("mousemove", moveHandler);
    document.removeEventListener("touchmove", moveHandler);
    document.removeEventListener("mouseup", (0, exports.handleMouseUp)(moveHandler));
    document.removeEventListener("touchend", (0, exports.handleMouseUp)(moveHandler));
};
exports.handleMouseUp = handleMouseUp;
const handleTouchMove = (thresholdKey, startX, startValue, thresholdValues, sliderWidth, setThresholdValues, setInputValue, setErrorMessage) => (moveEvent) => {
    const touch = moveEvent.touches[0];
    const clientX = touch.clientX;
    setInputValue(clientX.toString());
    (0, exports.handleMouseMove)(thresholdKey, startX, startValue, thresholdValues, sliderWidth, setThresholdValues, setInputValue)({
        clientX: touch.clientX,
    });
};
exports.handleTouchMove = handleTouchMove;
const handleMouseDown = (thresholdKey, thresholdValues, sliderWidth, setThresholdValues, setInputValue, setErrorMessage) => (event) => {
    if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
    }
    setErrorMessage("");
    const startX = event.clientX;
    const startValue = thresholdValues[thresholdKey];
    const moveHandler = (0, exports.handleMouseMove)(thresholdKey, startX, startValue, thresholdValues, sliderWidth, setThresholdValues, setInputValue);
    document.addEventListener("mousemove", moveHandler);
    document.addEventListener("mouseup", (0, exports.handleMouseUp)(moveHandler));
};
exports.handleMouseDown = handleMouseDown;
const handleTouchStart = (thresholdKey, thresholdValues, sliderWidth, setThresholdValues, setInputValue, setErrorMessage) => (event) => {
    if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
    }
    setErrorMessage("");
    const startX = event.touches[0].clientX;
    const startValue = thresholdValues[thresholdKey];
    setInputValue(startX.toString());
    const moveHandler = (0, exports.handleTouchMove)(thresholdKey, startX, startValue, thresholdValues, sliderWidth, setThresholdValues, setInputValue, setErrorMessage);
    setInputValue(startValue.toString());
    document.addEventListener("touchmove", moveHandler);
    document.addEventListener("touchend", (0, exports.handleMouseUp)(moveHandler));
};
exports.handleTouchStart = handleTouchStart;
