"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.yellow = exports.white = exports.waterColor = exports.theme = exports.textColor = exports.strokeColor = exports.rose = exports.roadColor = exports.red = exports.royalPurple = exports.reservationFillColor = exports.provinceStrokeColor = exports.orange = exports.mobileStreamPath = exports.mint = exports.mapWater = exports.mapStroke = exports.mapLandscape = exports.mapLabels = exports.lightCrimson = exports.lightBlueInfo = exports.lightBlue = exports.landscapeColor = exports.internationalBorderFillColor = exports.grey = exports.green = exports.grayStroke = exports.gray900 = exports.gray800 = exports.gray700 = exports.gray600 = exports.gray500 = exports.gray400 = exports.gray300 = exports.gray200 = exports.gray100 = exports.disabledGraphButton = exports.darkBlueTransparent = exports.darkBlue = exports.cta = exports.buildingStrokeColor = exports.blue = exports.black = exports.administrativeRegionTextColor = exports.acBlueTransparent = exports.acBlue = void 0;
const cta = "#FFE651";
exports.cta = cta;
const theme = "#00B2EF";
exports.theme = theme;
const white = "#FFFFFF";
exports.white = white;
const gray100 = "#F4F6F9";
exports.gray100 = gray100;
const gray200 = "#C0C9D1";
exports.gray200 = gray200;
const gray300 = "#7D858C";
exports.gray300 = gray300;
const gray400 = "#3E4449";
exports.gray400 = gray400;
const gray500 = "#C6CDD3";
exports.gray500 = gray500;
const gray600 = "#AFB6BC";
exports.gray600 = gray600;
const gray700 = "#CCC";
exports.gray700 = gray700;
const gray800 = "#4C5660A1";
exports.gray800 = gray800;
const gray900 = "#4C56601A";
exports.gray900 = gray900;
const mint = "#81DBCB";
exports.mint = mint;
const blue = "#00B3EF";
exports.blue = blue;
const acBlue = "#00B2EF";
exports.acBlue = acBlue;
const acBlueTransparent = "#CCF0FC";
exports.acBlueTransparent = acBlueTransparent;
const darkBlue = "#5C81A5";
exports.darkBlue = darkBlue;
const darkBlueTransparent = "#5c81a580";
exports.darkBlueTransparent = darkBlueTransparent;
const red = "#E95F5F";
exports.red = red;
const orange = "#FCA443";
exports.orange = orange;
const yellow = "#FFD960";
exports.yellow = yellow;
const green = "#96D788";
exports.green = green;
const lightCrimson = "#ED6B86";
exports.lightCrimson = lightCrimson;
const rose = "#FF38B5";
exports.rose = rose;
const royalPurple = "#7D3EBE";
exports.royalPurple = royalPurple;
const grey = "#a0a2ad";
exports.grey = grey;
const black = "#000";
exports.black = black;
const mapLabels = "#acacac";
exports.mapLabels = mapLabels;
const mapLandscape = "#f2f2f2";
exports.mapLandscape = mapLandscape;
const mapStroke = "#c9c9c9";
exports.mapStroke = mapStroke;
const mapWater = "#e0f1f9";
exports.mapWater = mapWater;
const mobileStreamPath = "#cee6f1";
exports.mobileStreamPath = mobileStreamPath;
const disabledGraphButton = "#a3a0a4";
exports.disabledGraphButton = disabledGraphButton;
const lightBlue = "#9BCDDE";
exports.lightBlue = lightBlue;
const grayStroke = "#DFE7F2";
exports.grayStroke = grayStroke;
const lightBlueInfo = "#00b2ef1a";
exports.lightBlueInfo = lightBlueInfo;
// Map styles colors
const reservationFillColor = "#e8ede9";
exports.reservationFillColor = reservationFillColor;
const textColor = "#acacac";
exports.textColor = textColor;
const strokeColor = "#ffffff";
exports.strokeColor = strokeColor;
const administrativeRegionTextColor = "#afafaf";
exports.administrativeRegionTextColor = administrativeRegionTextColor;
const internationalBorderFillColor = "#4e5256";
exports.internationalBorderFillColor = internationalBorderFillColor;
const landscapeColor = "#f2f2f2";
exports.landscapeColor = landscapeColor;
const buildingStrokeColor = "#d9dce8";
exports.buildingStrokeColor = buildingStrokeColor;
const provinceStrokeColor = "#5f6368";
exports.provinceStrokeColor = provinceStrokeColor;
const roadColor = "#ffffff";
exports.roadColor = roadColor;
const waterColor = "#e0f1f9";
exports.waterColor = waterColor;
