"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimelapseMarkers = void 0;
const react_google_maps_1 = require("@vis.gl/react-google-maps");
const react_1 = require("react");
const react_redux_1 = require("react-redux");
const thresholdSlice_1 = require("../../../store/thresholdSlice");
const mapParamsHandler_1 = require("../../../utils/mapParamsHandler");
const thresholdColors_1 = require("../../../utils/thresholdColors");
const clusterOverlay_1 = require("./clusterOverlay");
const customMarkerLabel_1 = require("./customMarkerLabel");
const customMarkerOverlay_1 = require("./customMarkerOverlay");
const calculateZIndex = (sessions) => {
    return sessions === 1
        ? Number(google.maps.Marker.MAX_ZINDEX) + 2
        : Number(google.maps.Marker.MAX_ZINDEX) + 1;
};
const createPosition = (lat, lng) => new google.maps.LatLng(lat, lng);
const createClusterOverlay = (key, session, color, map) => new clusterOverlay_1.ClusterOverlay({
    id: key,
    position: createPosition(session.latitude, session.longitude),
    count: session.sessions,
}, color, false, map, () => { });
const createMarkerOverlay = (session, color, map) => {
    const position = createPosition(session.latitude, session.longitude);
    const markerOverlay = new customMarkerOverlay_1.CustomMarkerOverlay(position, color, false, false);
    markerOverlay.setMap(map);
    return markerOverlay;
};
const createLabelOverlay = (session, color, unitSymbol, map) => {
    const position = createPosition(session.latitude, session.longitude);
    const zIndex = calculateZIndex(session.sessions);
    const labelOverlay = new customMarkerLabel_1.LabelOverlay(position, color, session.value, unitSymbol, false, () => { }, zIndex);
    labelOverlay.setMap(map);
    return labelOverlay;
};
const TimelapseMarkers = ({ sessions }) => {
    const thresholds = (0, react_redux_1.useSelector)(thresholdSlice_1.selectThresholds);
    const { unitSymbol } = (0, mapParamsHandler_1.useMapParams)();
    const map = (0, react_google_maps_1.useMap)();
    const markersRef = (0, react_1.useRef)(new Map());
    const memoizedSessions = (0, react_1.useMemo)(() => sessions, [sessions]);
    const updateExistingMarker = (timelapseMarker, session, isCluster, color, key) => {
        if (isCluster &&
            !(timelapseMarker.markerOverlay instanceof clusterOverlay_1.ClusterOverlay)) {
            convertToCluster(timelapseMarker, session, color, key);
        }
        else if (!isCluster &&
            timelapseMarker.markerOverlay instanceof clusterOverlay_1.ClusterOverlay) {
            convertToMarker(timelapseMarker, session, color);
        }
        else {
            updateMarker(timelapseMarker, session, color, key);
        }
    };
    const convertToCluster = (timelapseMarker, session, color, key) => {
        timelapseMarker.markerOverlay.setMap(null);
        if (timelapseMarker.labelOverlay)
            timelapseMarker.labelOverlay.setMap(null);
        timelapseMarker.markerOverlay = createClusterOverlay(key, session, color, map);
        timelapseMarker.labelOverlay = null;
    };
    const convertToMarker = (timelapseMarker, session, color) => {
        timelapseMarker.markerOverlay.setMap(null);
        timelapseMarker.markerOverlay = createMarkerOverlay(session, color, map);
        timelapseMarker.labelOverlay = createLabelOverlay(session, color, unitSymbol, map);
    };
    const updateMarker = (timelapseMarker, session, color, key) => {
        if (timelapseMarker.markerOverlay instanceof clusterOverlay_1.ClusterOverlay) {
            // Create a new cluster overlay instead of updating the existing one
            timelapseMarker.markerOverlay.setMap(null);
            timelapseMarker.markerOverlay = createClusterOverlay(key, session, color, map);
        }
        else {
            timelapseMarker.markerOverlay.setColor(color);
        }
        if (timelapseMarker.labelOverlay) {
            timelapseMarker.labelOverlay.update(false, color, session.value, unitSymbol);
        }
    };
    const createNewMarker = (session, isCluster, color, key) => {
        if (isCluster) {
            markersRef.current.set(key, {
                markerOverlay: createClusterOverlay(key, session, color, map),
                labelOverlay: null,
            });
        }
        else {
            markersRef.current.set(key, {
                markerOverlay: createMarkerOverlay(session, color, map),
                labelOverlay: createLabelOverlay(session, color, unitSymbol, map),
            });
        }
    };
    const removeObsoleteMarkers = (sessionsMap) => {
        markersRef.current.forEach((timelapseMarker, key) => {
            if (!sessionsMap.has(key)) {
                timelapseMarker.markerOverlay.setMap(null);
                if (timelapseMarker.labelOverlay)
                    timelapseMarker.labelOverlay.setMap(null);
                markersRef.current.delete(key);
            }
        });
    };
    (0, react_1.useEffect)(() => {
        if (!map)
            return;
        const sessionsMap = new Map(memoizedSessions.map((session) => [
            `${session.latitude}-${session.longitude}`,
            session,
        ]));
        sessionsMap.forEach((session, key) => {
            const color = (0, thresholdColors_1.getColorForValue)(thresholds, session.value);
            const isCluster = session.sessions > 1;
            let timelapseMarker = markersRef.current.get(key);
            if (timelapseMarker) {
                updateExistingMarker(timelapseMarker, session, isCluster, color, key);
            }
            else {
                createNewMarker(session, isCluster, color, key);
            }
        });
        removeObsoleteMarkers(sessionsMap);
    }, [map, memoizedSessions, thresholds, unitSymbol]);
    (0, react_1.useEffect)(() => {
        return () => {
            markersRef.current.forEach((timelapseMarker) => {
                timelapseMarker.markerOverlay.setMap(null);
                if (timelapseMarker.labelOverlay) {
                    timelapseMarker.labelOverlay.setMap(null);
                }
            });
            markersRef.current.clear();
        };
    }, []);
    return null;
};
exports.TimelapseMarkers = TimelapseMarkers;
