"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setSensor = void 0;
const filters_1 = require("../types/filters");
const sensors_1 = require("../types/sensors");
const setSensor = (selectedParameter, sensors, sessionType) => {
    const getSensor = (parameter, sensorName) => {
        const matchingSensors = sensors.filter((item) => item.measurementType === parameter &&
            (!sensorName || item.sensorName === sensorName));
        return matchingSensors[0];
    };
    let sensorName = "";
    switch (selectedParameter) {
        case filters_1.ParameterTypes.PARTICULATE_MATTER:
            sensorName =
                sessionType === filters_1.SessionTypes.FIXED
                    ? sensors_1.SENSOR_NAMES.PARTICULATE_MATTER.GOVERNMENT_PM25
                    : sensors_1.SENSOR_NAMES.PARTICULATE_MATTER.AIRBEAM_PM25;
            break;
        case filters_1.ParameterTypes.HUMIDITY:
            sensorName = sensors_1.SENSOR_NAMES.HUMIDITY;
            break;
        case filters_1.ParameterTypes.NITROGEN_DIOXIDE:
            sensorName = sensors_1.SENSOR_NAMES.NITROGEN_DIOXIDE;
            break;
        case filters_1.ParameterTypes.OZONE:
            sensorName = sensors_1.SENSOR_NAMES.OZONE;
            break;
        case filters_1.ParameterTypes.TEMPERATURE:
            sensorName = sensors_1.SENSOR_NAMES.TEMPERATURE;
            break;
        case filters_1.ParameterTypes.SOUND_LEVEL:
            sensorName = sensors_1.SENSOR_NAMES.SOUND_LEVEL;
            break;
        default:
            return {
                sensorName: getSensor(selectedParameter).sensorName,
                unitSymbol: getSensor(selectedParameter).unitSymbol,
            };
    }
    const sensor = getSensor(selectedParameter, sensorName);
    if (!sensor) {
        console.warn(`No sensor found for parameter: ${selectedParameter} with name: ${sensorName}`);
        return {
            sensorName: "Unknown Sensor",
            unitSymbol: "N/A",
        };
    }
    return {
        sensorName: sensor.sensorName,
        unitSymbol: sensor.unitSymbol,
    };
};
exports.setSensor = setSensor;
