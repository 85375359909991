"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProfileNamesInput = void 0;
const downshift_1 = require("downshift");
const react_1 = __importStar(require("react"));
const react_i18next_1 = require("react-i18next");
const booleans_1 = require("../../const/booleans");
const hooks_1 = require("../../store/hooks");
const mapSlice_1 = require("../../store/mapSlice");
const sessionFiltersSlice_1 = require("../../store/sessionFiltersSlice");
const filters_1 = require("../../types/filters");
const mapParamsHandler_1 = require("../../utils/mapParamsHandler");
const Spinner_1 = require("../Loader/Spinner");
const FilterInfoPopup_1 = require("./FilterInfoPopup");
const S = __importStar(require("./SessionFilters.style"));
const ProfileNamesInput = () => {
    const [items, setItems] = (0, react_1.useState)([""]);
    const [inputValue, setInputValue] = (0, react_1.useState)("");
    const [selectedItem, setSelectedItem] = (0, react_1.useState)("");
    const dispatch = (0, hooks_1.useAppDispatch)();
    const { t } = (0, react_i18next_1.useTranslation)();
    const { boundWest, boundEast, boundNorth, boundSouth, sensorName, sessionType, setFilter, tags, timeFrom, timeTo, unitSymbol, usernames, isIndoor, isActive, } = (0, mapParamsHandler_1.useMapParams)();
    const isFirstRender = (0, react_1.useRef)(true);
    const profileNames = (0, hooks_1.useAppSelector)(sessionFiltersSlice_1.selectUsernames);
    const isIndoorParameterInUrl = isIndoor === booleans_1.TRUE;
    const isUsernamesInputFetching = (0, hooks_1.useAppSelector)(sessionFiltersSlice_1.selectIsUsernamesInputFetching);
    const selectedSessionType = sessionType || filters_1.SessionTypes.FIXED;
    const preparedUnitSymbol = unitSymbol.replace(/"/g, "");
    const tagsDecoded = tags && decodeURIComponent(tags);
    const getQueryParams = (usernames) => {
        return {
            tags: tagsDecoded,
            west: boundWest.toString(),
            east: boundEast.toString(),
            south: boundSouth.toString(),
            north: boundNorth.toString(),
            timeFrom: timeFrom,
            timeTo: timeTo,
            usernames: usernames,
            sensorName: sensorName,
            unitSymbol: preparedUnitSymbol,
            sessionType: selectedSessionType,
            isIndoor: isIndoor === booleans_1.TRUE,
            isActive: isActive,
        };
    };
    const { isOpen, getMenuProps, getInputProps, getItemProps, reset } = (0, downshift_1.useCombobox)({
        items: profileNames,
        inputValue,
        selectedItem,
        onInputValueChange: ({ inputValue }) => {
            const queryParams = getQueryParams(inputValue);
            dispatch((0, sessionFiltersSlice_1.fetchUsernames)(queryParams));
            setInputValue(inputValue);
        },
        onSelectedItemChange: ({ selectedItem: newSelectedItem }) => {
            if (newSelectedItem !== null &&
                !(decodedUsernamesArray === null || decodedUsernamesArray === void 0 ? void 0 : decodedUsernamesArray.includes(newSelectedItem))) {
                const decodedUsernames = usernames && decodeURIComponent(usernames);
                const selectedUsernames = decodedUsernames + ", " + newSelectedItem;
                const urlEncodedString = encodeURIComponent(selectedUsernames);
                setFilter(mapParamsHandler_1.UrlParamsTypes.usernames, urlEncodedString.toString());
                reset();
                setSelectedItem("");
            }
        },
    });
    const handleOnInputClick = () => {
        const queryParams = getQueryParams(inputValue);
        dispatch((0, sessionFiltersSlice_1.fetchUsernames)(queryParams));
    };
    const decodedUsernamesArray = usernames &&
        decodeURIComponent(usernames)
            .split(", ")
            .filter((el) => el !== "");
    const displaySearchResults = isOpen && items.length > 0;
    const handleOnClose = (itemToRemove) => {
        const usernamesUpdated = decodedUsernamesArray &&
            decodedUsernamesArray.filter((el) => el !== itemToRemove);
        const decodedUsernamesString = usernamesUpdated
            ? usernamesUpdated.join(", ")
            : "";
        setFilter(mapParamsHandler_1.UrlParamsTypes.usernames, decodedUsernamesString.toString());
    };
    (0, react_1.useEffect)(() => {
        setItems(profileNames);
    }, [profileNames]);
    (0, react_1.useEffect)(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        else {
            const timer = setTimeout(() => {
                dispatch((0, mapSlice_1.setFetchingData)(true));
            }, 200);
            return () => clearTimeout(timer);
        }
    }, [usernames, dispatch]);
    return (react_1.default.createElement(S.Wrapper, null,
        react_1.default.createElement(S.SingleFilterWrapper, null,
            react_1.default.createElement(S.InputWrapper, null,
                react_1.default.createElement(S.Input, Object.assign({ placeholder: t("filters.profileNames") }, getInputProps({
                    value: inputValue,
                    onClick: handleOnInputClick,
                }), { disabled: isIndoorParameterInUrl })),
                isUsernamesInputFetching && react_1.default.createElement(Spinner_1.Spinner, null)),
            react_1.default.createElement(FilterInfoPopup_1.FilterInfoPopup, { filterTranslationLabel: "filters.profileNamesInfo" })),
        decodedUsernamesArray && decodedUsernamesArray.length > 0 && (react_1.default.createElement(S.SelectedItemsWrapper, null, decodedUsernamesArray.map((item, index) => (react_1.default.createElement(S.SelectedItemTile, { key: index },
            react_1.default.createElement(S.SelectedItem, null, item),
            react_1.default.createElement(S.CloseSelectedItemButton, { onClick: () => handleOnClose(item) })))))),
        react_1.default.createElement(S.SuggestionList, Object.assign({ "$displaySearchResults": displaySearchResults }, getMenuProps()), items.map((item, index) => (react_1.default.createElement(S.Suggestion, Object.assign({ key: index }, getItemProps({ item, index })), item))))));
};
exports.ProfileNamesInput = ProfileNamesInput;
