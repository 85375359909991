"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAutoDismissAlert = void 0;
const react_1 = require("react");
const useAutoDismissAlert = (showAlert, setShowAlert) => {
    (0, react_1.useEffect)(() => {
        if (showAlert) {
            const timer = setTimeout(() => {
                setShowAlert(false);
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [showAlert, setShowAlert]);
};
exports.useAutoDismissAlert = useAutoDismissAlert;
