"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CrowdMapGridSize = exports.defaultGridSize = void 0;
const react_1 = __importStar(require("react"));
const crowdMapSlice_1 = require("../../store/crowdMapSlice");
const hooks_1 = require("../../store/hooks");
const mapParamsHandler_1 = require("../../utils/mapParamsHandler");
const S = __importStar(require("./SessionFilters.style"));
const gridSizes = [1, 10, 20, 30, 40];
exports.defaultGridSize = 51 - gridSizes[2];
const CrowdMapGridSize = () => {
    const dispatch = (0, hooks_1.useAppDispatch)();
    const { setFilter, gridSize } = (0, mapParamsHandler_1.useMapParams)();
    const handleGridSize = (0, react_1.useCallback)((size) => {
        setFilter(mapParamsHandler_1.UrlParamsTypes.gridSize, (51 - size).toString());
        setTimeout(() => {
            dispatch((0, crowdMapSlice_1.setFetchingCrowdMapData)(true));
        }, 200);
    }, [dispatch, gridSize, setFilter]);
    return (react_1.default.createElement(S.SectionButtonsContainer, null, gridSizes.map((size) => (react_1.default.createElement(S.SectionButton, { key: size, onClick: () => handleGridSize(size), "$isActive": gridSize === 51 - size }, size)))));
};
exports.CrowdMapGridSize = CrowdMapGridSize;
