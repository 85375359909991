"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const useMapEventListeners = (map, eventHandlers) => {
    (0, react_1.useEffect)(() => {
        if (map) {
            Object.entries(eventHandlers).forEach(([eventName, handler]) => {
                map.addListener(eventName, handler);
            });
            // Cleanup function to remove listeners on unmount
            return () => {
                if (map) {
                    Object.keys(eventHandlers).forEach((eventName) => {
                        google.maps.event.clearListeners(map, eventName);
                    });
                }
            };
        }
    }, [map, eventHandlers]);
};
exports.default = useMapEventListeners;
