"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lastItemFromArray = void 0;
const lastItemFromArray = (array) => {
    if (array.length === 0)
        return undefined;
    const sortedArray = [...array].sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());
    return sortedArray[sortedArray.length - 1];
};
exports.lastItemFromArray = lastItemFromArray;
