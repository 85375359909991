"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectTimelapseTimeRange = exports.selectTimelapseIsLoading = exports.selectCurrentTimestamp = exports.selectTimelapseData = void 0;
const selectTimelapseData = (state) => state.timelapse.data;
exports.selectTimelapseData = selectTimelapseData;
const selectCurrentTimestamp = (state) => state.timelapse.currentTimestamp;
exports.selectCurrentTimestamp = selectCurrentTimestamp;
const selectTimelapseIsLoading = (state) => state.timelapse.isLoading;
exports.selectTimelapseIsLoading = selectTimelapseIsLoading;
const selectTimelapseTimeRange = (state) => state.timelapse.timelapseTimeRange;
exports.selectTimelapseTimeRange = selectTimelapseTimeRange;
