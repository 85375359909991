"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DormantToggle = void 0;
const react_1 = __importStar(require("react"));
const react_i18next_1 = require("react-i18next");
const fixedSessionsSelectors_1 = require("../../store/fixedSessionsSelectors");
const hooks_1 = require("../../store/hooks");
const mapSlice_1 = require("../../store/mapSlice");
const sessionFiltersSlice_1 = require("../../store/sessionFiltersSlice");
const mapParamsHandler_1 = require("../../utils/mapParamsHandler");
const Toggle_1 = require("../Toggle/Toggle");
const FilterInfoPopup_1 = require("./FilterInfoPopup");
const S = __importStar(require("./SessionFilters.style"));
const YearPickerButtons_1 = require("./YearPickerButtons");
const DormantToggle = () => {
    const { t } = (0, react_i18next_1.useTranslation)();
    const dispatch = (0, hooks_1.useAppDispatch)();
    const { isActive, updateIsActive, updateTime } = (0, mapParamsHandler_1.useMapParams)();
    const [isDormant, setIsDormant] = (0, react_1.useState)(false);
    const isDormantSessionsFetched = (0, hooks_1.useAppSelector)(fixedSessionsSelectors_1.selectIsDormantSessionsFetched);
    const isActiveSessionsFetched = (0, hooks_1.useAppSelector)(fixedSessionsSelectors_1.selectIsActiveSessionsFetched);
    const handleToggleClick = (0, react_1.useCallback)(() => {
        const newDormantState = !isDormant;
        setIsDormant(newDormantState);
        const currentYear = new Date().getFullYear();
        if (newDormantState === true) {
            updateIsActive(false);
            dispatch((0, sessionFiltersSlice_1.setFixedSessionsType)(sessionFiltersSlice_1.FixedSessionsTypes.DORMANT));
        }
        else {
            updateTime(currentYear);
            updateIsActive(true);
            dispatch((0, sessionFiltersSlice_1.setFixedSessionsType)(sessionFiltersSlice_1.FixedSessionsTypes.ACTIVE));
        }
        dispatch((0, mapSlice_1.setFetchingData)(true));
    }, [
        isDormant,
        isDormantSessionsFetched,
        isActiveSessionsFetched,
        dispatch,
        updateIsActive,
        updateTime,
    ]);
    (0, react_1.useEffect)(() => {
        setIsDormant(!isActive);
        if (!isActive) {
            dispatch((0, sessionFiltersSlice_1.setFixedSessionsType)(sessionFiltersSlice_1.FixedSessionsTypes.DORMANT));
        }
    }, [isActive]);
    return (react_1.default.createElement(S.Wrapper, null,
        react_1.default.createElement(S.SingleFilterWrapper, null,
            react_1.default.createElement(S.ToggleSettingsContainer, { "$isActive": isDormant },
                react_1.default.createElement(S.ToggleWrapper, { onClick: handleToggleClick },
                    react_1.default.createElement(Toggle_1.Toggle, { isChecked: isDormant, onChange: handleToggleClick, variant: "toggle", noLabel: true, biggerMobileVersion: true }),
                    react_1.default.createElement(S.CrowdMapToggleText, null,
                        t("filters.dormantToggleLabel"),
                        " ",
                        react_1.default.createElement(S.CrowdMapToggleOnOff, null, isDormant ? t("filters.on") : t("filters.off")))),
                isDormant && (react_1.default.createElement(S.CrowdMapGridSizeWrapper, { "$isVisible": isDormant },
                    react_1.default.createElement(S.DormantYearPickerWrapper, null,
                        t("filters.yearPickerHeader"),
                        react_1.default.createElement(YearPickerButtons_1.YearPickerButtons, null))))),
            react_1.default.createElement(FilterInfoPopup_1.FilterInfoPopup, { filterTranslationLabel: "filters.dormantInfo" }))));
};
exports.DormantToggle = DormantToggle;
