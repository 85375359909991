"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const colors_1 = require("../../assets/styles/colors");
exports.default = [
    {
        featureType: "administrative",
        elementType: "geometry.fill",
        stylers: [
            {
                color: colors_1.reservationFillColor,
            },
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative",
        elementType: "labels.icon",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: colors_1.textColor,
            },
            {
                weight: 0.125,
            },
        ],
    },
    {
        featureType: "administrative",
        elementType: "labels.text.stroke",
        stylers: [
            {
                color: colors_1.strokeColor,
            },
        ],
    },
    {
        featureType: "administrative.country",
        elementType: "labels",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "administrative.country",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: colors_1.administrativeRegionTextColor,
            },
        ],
    },
    {
        featureType: "administrative.country",
        elementType: "geometry.fill",
        stylers: [
            {
                color: colors_1.internationalBorderFillColor,
            },
        ],
    },
    {
        featureType: "administrative.land_parcel",
        elementType: "labels",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.locality",
        stylers: [
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "administrative.neighborhood",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.neighborhood",
        elementType: "labels",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "administrative.neighborhood",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: colors_1.textColor,
            },
        ],
    },
    {
        featureType: "administrative.province",
        elementType: "geometry.stroke",
        stylers: [{ color: colors_1.provinceStrokeColor }],
    },
    {
        featureType: "landscape",
        stylers: [
            {
                color: colors_1.landscapeColor,
            },
        ],
    },
    {
        featureType: "landscape.man_made",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "landscape.man_made",
        elementType: "geometry",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "landscape.man_made",
        elementType: "geometry.fill",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "landscape.man_made",
        elementType: "geometry.stroke",
        stylers: [
            {
                color: colors_1.buildingStrokeColor,
            },
            {
                lightness: -5,
            },
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "landscape.natural",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "landscape.natural.landcover",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "landscape.natural.terrain",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "poi",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi",
        elementType: "labels",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.attraction",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.business",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "poi.park",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road",
        stylers: [
            {
                color: colors_1.roadColor,
            },
            {
                saturation: -100,
            },
            {
                lightness: 45,
            },
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "labels",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road.arterial",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: colors_1.textColor,
            },
        ],
    },
    {
        featureType: "road.highway",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "geometry.fill",
        stylers: [
            {
                color: colors_1.roadColor,
            },
        ],
    },
    {
        featureType: "road.highway",
        elementType: "labels",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "road.local",
        elementType: "labels",
        stylers: [
            {
                visibility: "simplified",
            },
        ],
    },
    {
        featureType: "road.local",
        elementType: "labels.text.fill",
        stylers: [
            {
                color: colors_1.textColor,
            },
        ],
    },
    {
        featureType: "transit",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
    {
        featureType: "water",
        stylers: [
            {
                color: colors_1.waterColor,
            },
            {
                visibility: "on",
            },
        ],
    },
    {
        featureType: "water",
        elementType: "labels",
        stylers: [
            {
                visibility: "off",
            },
        ],
    },
];
