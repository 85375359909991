"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectTotalMarkers = exports.selectLoadedMarkers = exports.selectMarkersLoading = exports.incrementLoadedMarkers = exports.setTotalMarkers = exports.setMarkersLoading = void 0;
const toolkit_1 = require("@reduxjs/toolkit");
const initialState = {
    isLoading: false,
    totalMarkers: 0,
    loadedMarkers: 0,
};
const markersLoadingSlice = (0, toolkit_1.createSlice)({
    name: "markersLoading",
    initialState,
    reducers: {
        setMarkersLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setTotalMarkers: (state, action) => {
            state.totalMarkers = action.payload;
            state.loadedMarkers = 0;
        },
        incrementLoadedMarkers: (state) => {
            state.loadedMarkers += 1;
            if (state.loadedMarkers >= state.totalMarkers) {
                state.isLoading = false;
            }
        },
    },
});
_a = markersLoadingSlice.actions, exports.setMarkersLoading = _a.setMarkersLoading, exports.setTotalMarkers = _a.setTotalMarkers, exports.incrementLoadedMarkers = _a.incrementLoadedMarkers;
exports.default = markersLoadingSlice.reducer;
const selectMarkersLoading = (state) => state.markersLoading.isLoading;
exports.selectMarkersLoading = selectMarkersLoading;
const selectLoadedMarkers = (state) => state.markersLoading.loadedMarkers;
exports.selectLoadedMarkers = selectLoadedMarkers;
const selectTotalMarkers = (state) => state.markersLoading.totalMarkers;
exports.selectTotalMarkers = selectTotalMarkers;
